.p-auth-header {
    background-color: var(--theme-background-color);
    color: var(--text-color);
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    width: 100%;
    min-height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: sticky;
    top: 0;
    z-index: 99;
    opacity: 0.97;
    @media (min-width: 600px) {
        width: calc(100 * var(--vw));
    }

    .left {
        position: absolute;
        height: 100%;
        min-width: 60px;
        left: 0;
        .header-back-btn {
            position: absolute;
            font-size: 28px;
            top: 16px;
            left: 16px;
            z-index: 100;
        }
    }
    .middle {
        .info-request {
            width: auto;
            position: initial;
        }
        .info-title {
            padding-top: 0px;
            margin-top: 4px;
        }
        .info-address {
            width: 140px;
            padding-top: 2px;
            margin: auto;
            margin-top: 10px;
            text-align: center;
            justify-content: center;
            align-items: center;
            .copy-icon {
                position: relative;
                top: 1px;
            }
        }
    }
    .right {
        position: absolute;
        position: absolute;
        height: 100%;
        min-width: 60px;
        right: 0;
    }
}

@primary-color: #5177f9;