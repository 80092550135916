.risk-reminder {
    min-width: 290px;
    width: 100%;
    position: fixed;
    bottom: 94px;
    background: var(--theme-background-color);
    z-index: 200;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 13px 13px 0px 0px;
    box-shadow: 0px -2px 3px rgba(0, 0, 0, 0.1);
    .risk-header {
        width: 100%;
        font-size: 14px;
        font-weight: bold;
        color: var(--text-color);
        flex-direction: row;
        display: flex;
        align-items: center;
        padding: 12px 18px 2px 18px;
        .risk-red-dot {
            width: 6px;
            height: 6px;
            background: var(--error-color);
            border-radius: 50%;
        }
        .risk-found {
            margin-left: 6px;
            flex-grow: 1;
        }
        .btn-risk-fold {
            font-size: 16px;
            font-weight: normal;
        }
    }
}

@primary-color: #5177f9;