.bind-security-account-prompt {
    .bind-hint-content {
        font-size: 18px;
        font-weight: 500;
        margin: 30px 0;
        text-align: center;
        color: var(--text-color);
    }
    .prompt-bottom-btn-box {
        display: flex;
        gap: 16px;
    }
}

@primary-color: #5177f9;