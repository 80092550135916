.ant-modal-content {
    .ant-modal-footer {
        display: none;
    }
}
.wrong-password-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    .modal-title {
        font-size: 22px;
        color: var(--text-color);
        margin-top: 20px 0;
        text-align: center;
        line-height: 25px;
    }
    .tip {
        color: var(--text-color);
        font-size: 13px;
        font-weight: 400;
        margin-top: 16px;
        margin-bottom: 20px;
        padding-left: 10px;
        padding-right: 10px;
        text-align: center;
    }
    .frozen-countdown {
        font-size: 24px;
        font-weight: bold;
        color: var(--text-color);
        margin-top: 12px;
        margin-bottom: 22px;
    }
    .btn {
        width: 100%;
        height: 47px;
        background: rgba(0, 0, 0, 0.39);
        opacity: 1;
        border-radius: var(--primary-btn-border-radius);
        margin-top: 10px;
        background: var(--primary-btn-background-color);
        color: var(--primary-btn-color);
        border: none;
        font-size: var(--primary-btn-font-size);
        font-weight: 600;

        &.cancel {
            background: var(--secondary-btn-background-color);
            color: var(--secondary-btn-color);
        }
    }
    .close-icon {
        align-self: flex-end;
    }
}

@primary-color: #5177f9;