.login-account-details {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 100%;
    color: var(--text-color);
    padding: 0 18px;
    .account-title {
        font-weight: 600;
        font-size: 18px;
        height: 60px;
        line-height: 60px;
        text-align: center;
    }
    .link-account-hint {
        align-self: center;
        font-size: 13px;
        color: var(--text-color);
        padding-top: 16px;
    }

    .account-name-title {
        align-self: self-start;
        font-size: 14px;
        color: var(--text-color);
        font-weight: 600;
        line-height: 16px;
        margin-top: 39px;
    }
    .account-name {
        align-self: self-start;
        font-size: 12px;
        color: var(--secondary-text-color);
        margin-top: 12px;
        height: 56px;
        line-height: 56px;
        border-radius: var(--card-border-radius);
        background-color: var(--card-unclickable-background-color);
        width: 100%;
        padding: 0 14px;
    }

    .account-type-title {
        align-self: self-start;
        font-size: 14px;
        color: var(--text-color);
        font-weight: 600;
        line-height: 16px;
        margin-top: 28px;
    }
    .card {
        margin-top: 12px;
        width: 100%;
        padding: 19px 14px;
        position: relative;
        border-radius: var(--card-border-radius);
        background-color: var(--card-unclickable-background-color);
        overflow: hidden;
        transition: height 0.2s ease-in-out;
        height: 120px;
        &.fold{
            height: 56px;
        }
        .account-value {
            align-self: self-start;
            font-size: 12px;
            color: var(--text-color);
            font-weight: 600;
            width: 100%;
            .icon-arrow {
                position: absolute;
                right: 6px;
                top: 13px;
                cursor: pointer;
                padding: 4px;
                cursor: pointer;
                &.icon-up{
                    transform: rotate(270deg);
                }
                &.icon-down{
                    transform: rotate(90deg);
                }
                svg {
                    font-size: 26px;
                    circle{
                        fill: none;
                    }
                }
            }
        }
        .create-at {
            color: var(--secondary-text-color);
            font-size: 12px;
            display: flex;
            width: 100%;
            justify-content: space-between;
            margin-top: 18px;
        }
        .btn-delete {
            color: var(--error-color);
            font-size: 12px;
            font-weight: 500;
            width: 100%;
            padding: 8px 0;
            padding-bottom: 0;
            cursor: pointer;
        }
    }
}

@primary-color: #5177f9;