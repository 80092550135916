:root {
    --app-background-color: #eaecf0;
    --hover-opacity: 0.85;
    --disable-opacity: 0.6;
    --error-color: #ea4335;
    --warning-color: #f7af5d;
    --green-color: #58c08f;
    --primary-btn-font-size: 17px;
    --primary-btn-border-radius: 18px;
    --modal-border-radius: 24px;
    --card-border-radius: 18px;

    --sign-bottom-menu-height: 94px;
    --set-payment-password-bar-height: 59px;
    --risk-bar-height: 0px;
    --loading-icon: url('../images/loading_fff.png');
    --icon-warning: url('../images/account/icon_waring_fff.png');
    --icon-danger: url('../images/no_warning.png');
    --master_password_icon: url('../images/master_password_icon_fff.svg');
    --close-icon: url('../images/close_fff_icon.svg');
    //light theme
    --keyword-all-bg: #ededed;
    --keyword-border-color: #f5f6fb;
    --keyword-item-bg: #fff;
    --keyword-item-active-bg: #ededed;
    --keyword-border-width: 1px;

    --border-color-1: #eaecf0;

    /* Light/中性色/#F9F9FA-弹窗文本框 输入框 */
    --border-color-2: #f9f9fa;
    /* Light/中性色/#FFFFFF-背景 */
    --background-color-1: #ffffff;

    //custom style (modify in dashboard)
    --primary-btn-color: #fff; //主按钮字体颜色
    --primary-btn-background-color: #000; //主按钮背景色
    --secondary-btn-color: #000000; //次要按钮字体颜色
    --secondary-btn-background-color: #eff0f2; //次要钮背景色
    --text-color: #000; //主文字颜色
    --secondary-text-color: #8b8ea1; //次要内容文案颜色
    --background-color: #fff; //背景颜色
    --icon-border-color: #f5f6fb; //图标背景颜色（登录页面social login 图标描边）
    --accent-color: #a257fa; //着重色
    --accent-shadow-color: rgba(162, 87, 250, 0.2);
    --input-background-color: #f9f9fa; // 二级界面文本框/输入框
    --input-background-color-3: #f5f6fb;// 三级输入框背景颜色
    --input-border-color: #f5f3f5; //输入框边框颜色
    --input-placeholder-color: #8b8ea1; //输入框提示文字颜色
    --card-hover-border-color: #86899d; //可点击card获取焦点时边框颜色
    --card-border-color: #dcdfe6; //可点击card边框颜色
    --card-unclickable-background-color: #f9f9fa; //不可点击card背景色
    --card-unclickable-border-color: #eef2f9; //不可点击card边框颜色
    --card-divider-color: #eaecf0; //card 内部分割线颜色
    --tag-background-color: rgba(139, 142, 159, 0.15); //标签背景色（复制地址框...）
    --modal-background-color: #fff; //弹窗背景颜色
    --tips-background-color: #eab98133; //提示条背景颜色
    --low-emphasis-color: #dcdfe6; // 文本色/低强调
    --bg-color-2: #f5f6fb; /* Light/中性色/#F5F6FB-移动端首页文本框 */
    &.dark,
    body.dark {
        --app-background-color: #0c0e0f;
        --keyword-all-bg: #000;
        --keyword-item-bg: #212324;
        --keyword-border-color: #373841;
        --keyword-item-active-bg: #181c1e;
        --keyword-border-width: 1px;
        // --master_password_icon:url('../images/account/master_password_icon_000.png');
        --border-color-1: #7a7a7a;
        --master_password_icon: url('../images/master_password_icon_000.svg');
        --loading-icon: url('../images/loading_000.png');
        --close-icon: url('../images/close_000_icon.svg');
        /* Dark/中性色/#303139-弹窗文本框、输入框 */
        --border-color-2: #303139;
        /* Dark/中性色/#232529-弹窗背景 */
        --background-color-1: #232529;

        //custom style (modify in dashboard)
        --primary-btn-color: #000; //主按钮字体颜色
        --primary-btn-background-color: #fff; //主按钮背景色
        --secondary-btn-color: #fff; //次要按钮字体颜色
        --secondary-btn-background-color: #41424a; //次要钮背景色
        --text-color: #fff; //主文字颜色
        --secondary-text-color: #8b8ea1; //次要内容文案颜色
        --background-color: #000; //背景颜色
        --icon-border-color: #292b36; //图标背景颜色（登录页面social login 图标描边）
        --accent-color: #a257fa; //着重色
        --input-background-color: #232529; //二级界面文本框/输入框
        --input-background-color-3: #414346;// 三级输入框背景颜色
        --input-border-color: #252525; //输入框边框颜色
        --input-placeholder-color: #999999; //输入框提示文字颜色
        --card-hover-border-color: #35383a; //可点击card获取焦点时边框颜色
        --card-border-color: #313334; //可点击card边框颜色
        --card-unclickable-background-color: #232529; //不可点击card背景色  /* 二级界面文本框 /输入框 */
        --card-unclickable-border-color: #252525; //不可点击card边框颜色
        --card-divider-color: #303139; //card 内部分割线颜色
        --tag-background-color: rgba(139, 142, 159, 0.15); //标签背景色（复制地址框...）
        --modal-background-color: #232529; //弹窗背景颜色
        --tips-background-color: #eab98159; //提示条背景颜色
        --low-emphasis-color: #42444b; // 文本色/低强调

        --bg-color-2: #292b36; /* Dark/中性色/#292B36-移动端首页文本框 */
    }
}
