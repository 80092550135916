.footer-box {
    margin-bottom: 10px;
    width: 100%;
    .footer {
        width: 167px;
        margin: auto;
        display: block;
        user-select: none;
    }
}

@primary-color: #5177f9;