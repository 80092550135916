.ant-drawer-content {
    .ant-drawer-wrapper-body {
        .ant-drawer-header {
            display: none;
        }
    }
}
.particle-pc-drawer {
    .payment-verify-content {
        margin-top: 38px;
    }
}
.payment-verify-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    margin-top: 22px;
    .particle-keywords-map {
        position: absolute;
    }
}

@primary-color: #5177f9;