.alert-modal {
    .alert-content-message {
        color: var(--text-color);
        font-size: 18px;
        align-items: center;
        text-align: center;
        margin-top: 8px;
    }
    .alert-modal-btns {
        display: flex;
        justify-content: space-between;
        margin-top: 22px;
        gap: 20px;
    }
}

@primary-color: #5177f9;