.password-input {
    width: 100%;
    height: 47px;
    min-height: 47px;
    border: 1px solid var(--input-border-color) !important;
    border-radius: var(--primary-btn-border-radius);
    // background-color: var(--input-background-color);
    background: none;
    padding: 0;
    position: relative;
    overflow: hidden;
    input {
        background-color: var(--input-background-color);
        font-size: 15px;
        width: 100%;
        height: 45px;
        font-weight: 500;
        color: var(--text-color);
        padding: 0 8px;
        padding-left: 16px !important;
    }
    .ant-input-suffix {
        position: absolute;
        right: 14px;
        top: 12px;
        cursor: pointer;
        z-index: 1;
    }
}

@primary-color: #5177f9;