.captcha-box {
    text-align: center;
    .error-tip {
        // width: 100vw;
        // margin-top: 2vw;
        margin-top: 5px;
        position: absolute;
        left: calc((100vw - 316px) / 2);
        left: 4px;
        font-size: 12px;
        font-weight: 400;
        line-height: 19px;
        color: var(--error-color);
        left: 0;
        // right: 0;
        // margin-right: calc((100vw - 342px) / 2);
        @media screen and (max-width: 380px) {
            // margin-right: 5vw;
        }

        @media (min-width: 600px) {
            // left: calc((100 * var(--vw) - 316px) / 2);
        }
    }
    .get-btn {
        width: 316px;
        padding: 0;
        margin-top: 14px;
        border: none;
        box-shadow: none;
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        color: var(--text-color);
        background-color: transparent !important;
        @media screen and (max-width: 380px) {
            width: 90vw;
        }
        @media (min-width: 600px) {
            width: calc(90 * var(--vw));
        }
        &[disabled] {
            color: var(--secondary-text-color);
            font-weight: 400 !important;
            opacity: 1 !important;
        }
    }
    .submit-btn {
        margin-top: 38px;
        width: 322px;
        // @media screen and (max-width: 380px) {
        //     width: 90vw;
        // }
        // @media (min-width: 600px) {
        //     width: calc(90 * var(--vw));
        // }
    }
    .btn-box {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .back-box {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 60px;
        margin-bottom: 30px;
        gap: 8px;
        cursor: pointer;
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        color: var(--accent-color);
        position: relative;
        left: -4px;
        height: 19px;
        img {
            width: 9.42px;
            margin-right: 7px;
        }
        svg {
            color: var(--accent-color);
            position: relative;
            top: -1px;
        }
    }
    .captcha-title {
        text-align: center;
        font-size: 18px;
        font-weight: 700;
        color: var(--text-color);
        position: absolute;
        top: 14px;
        left: 0;
        right: 0;
    }
    .captcha-form {
        padding-top: 44px;
        .input-code-content {
            position: relative;
        }
    }
}

@primary-color: #5177f9;