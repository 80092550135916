.particle-drawer-container {
    .close-icon {
        position: absolute;
        top: 24px;
        right: 22px;
        cursor: pointer;
        &[data-mobile='true'] {
            display: none;
        }
    }
    .ant-drawer-content-wrapper {
        transition: transform 0.6s cubic-bezier(0.23, 1, 0.32, 1), box-shadow 0.3s cubic-bezier(0.23, 1, 0.32, 1);
    }
    .particle-title{
        color: var(--text-color);
        font-size: 22px;
        font-weight: 600;
        text-align: center;
        margin: 0;
    }
    .particle-drawer-content {
        width: 100%;

        .particle-close-btn {
            width: 39px;
            height: 4px;
            border-radius: 9px;
            background-color: var(--low-emphasis-color);
            margin: auto;
            margin-bottom: 18px;
            &[data-mobile='false'] {
                display: none;
            }
        }
        .gas-title {
            text-align: center;
        }
        .anticon-close {
            display: none;
        }
    }
}

@primary-color: #5177f9;