.device-list-container {
    width: 100%;
    color: var(--text-color);
    overflow: auto;
    height: var(--doc-height);
    padding-bottom: 80px;
    .devices-description {
        margin: 32px 18px 40px 18px;
        font-size: 13px;
        font-weight: 400;
        color: var(--secondary-text-color);
    }
    .ant-spin-nested-loading > div > .ant-spin {
        top: 10vh;
    }
    .device-list {
        // border-top: 1px solid var(--card-unclickable-border-color);
        width: 100%;
        padding: 0 18px;
        .ant-skeleton-content {
            .ant-skeleton-title {
                display: none;
            }
        }
        .item {
            cursor: pointer;
            height: 70px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            // border-bottom: 1px solid var(--card-unclickable-border-color);
            background: var(--card-unclickable-background-color);
            border-radius: var(--card-border-radius);
            margin-bottom: 10px;
            padding: 0 12px 0 14px;
            .device {
                font-size: 14px;
                font-weight: 600;
            }
            .created_at {
                font-weight: 400;
                color: var(--secondary-text-color);
                line-height: 1.2;
                font-size: 12px;
                margin-top: 4px;
            }
            .right {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                gap: 8px;
                .arrow-right-icon {
                    color: var(--text-color);
                    svg {
                        width: 12px;
                        height: 12px;
                    }
                }
                .current-icon {
                    height: 20px;
                    border-radius: 30px;
                    overflow: hidden;
                    color: var(--accent-color);
                    font-size: 13px;
                    padding: 0 10px;
                    line-height: 20px;
                    white-space: nowrap;
                    position: relative;
                    &:after {
                        content: '';
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                        background-color: var(--accent-color);
                        opacity: 0.15;
                    }
                }
            }
        }
    }
    .footer-box {
        height: 37px;
        bottom: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: var(--theme-background-color);
    }
}

@primary-color: #5177f9;