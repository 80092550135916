.account-and-security {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    flex-direction: column;
    height: var(--doc-height);
    overflow: hidden;
    position: relative;

    .scroll-content {
        // height: 0;
        flex: 1;
        overflow-y: auto;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding-bottom: 60px;
        width: 100%;

        .category-title {
            align-self: flex-start;
            margin-left: calc(5% + 12px);
            font-size: 14px;
            font-weight: bold;
            line-height: 16px;
            color: var(--text-color);
            margin-top: 8px;
        }
    }

    .hover {
        cursor: pointer;
        &:hover {
            opacity: var(--hover-opacity);
        }
    }

    .label-item-switch {
        box-shadow: none !important;
        transform: scale(0.98);
    }

    .label-item {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;
        .arrow-right-icon {
            color: var(--text-color);
            svg {
                width: 11px;
                height: 11px;
            }
        }
        .label-item-name {
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;
            color: var(--text-color);
        }

        .label-item-btn {
            cursor: pointer;
            min-width: 44px;
            padding: 0 13px;
            height: 22px;
            line-height: 22px;
            text-align: center;
            background: var(--primary-btn-background-color);
            opacity: 1;
            border-radius: var(--primary-btn-border-radius);
            font-size: 14px;
            font-weight: 500;
            line-height: 22px;
            color: var(--primary-btn-color);
            white-space: nowrap;

            &:hover {
                opacity: var(--hover-opacity);
            }
        }
    }

    .footer-box {
        position: absolute;
        height: 37px;
        margin: 0 !important;
        display: flex;
        align-items: center;
        bottom: 0;
        background: var(--theme-background-color);
        @media screen and (max-height: 720px) {
            margin-bottom: 10px;
            bottom: 0;
        }
    }

    .footer-tips {
        text-align: center;
        position: absolute;
        bottom: 50px;
        font-size: 14px;
        font-weight: 400;
        line-height: 11px;
        color: var(--text-color);
        line-height: 24px;
    }
    .title {
        font-size: 18px;
        font-weight: 600;
        color: var(--text-color);
        background-color: var(--bg-color);
        width: 100vw;
        text-align: center;
        background: var(--theme-background-color);
        position: sticky;
        top: 0;
        z-index: 1;
        padding-top: 18px;
        @media (min-width: 600px) {
            width: calc(100 * var(--vw));
        }
    }

    .payment-security {
        width: 90%;
        border-radius: var(--card-border-radius);
        margin-top: 18px;
        margin-bottom: 0px;
        padding: 14px 14px;
        box-sizing: border-box;
        background-color: var(--card-unclickable-background-color);
        h3 {
            font-size: 14px;
            font-weight: bold;
            line-height: 16px;
            color: var(--text-color);
            margin-bottom: 12px;
        }
        p {
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            color: var(--secondary-text-color);
            opacity: 1;
            padding-right: 20px;
            box-sizing: border-box;
        }
    }

    .authorization-item {
        margin-bottom: 20px;
        .label-item {
            margin: 0;
            height: 36px;
        }
    }

    .master-password-item {
        width: 90%;
        border-radius: var(--card-border-radius);
        background-color: var(--card-unclickable-background-color);
        margin-top: 18px;
        padding: 14px 14px;
        .mp-header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 12px;
            .mp-title {
                font-size: 14px;
                font-weight: bold;
                line-height: 16px;
                color: var(--text-color);
            }
            .mp-learn-more {
                color: var(--accent-color);
                cursor: pointer;
                font-size: 14px;
                line-height: 16px;
                font-weight: 500;
            }
        }
        .mp-content {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            .mp-desc {
                font-size: 14px;
                font-weight: 400;
                line-height: 18px;
                color: var(--secondary-text-color);
                opacity: 1;
                box-sizing: border-box;
                flex-shrink: 1;
            }
            .mp-set {
                cursor: pointer;
                min-width: 44px;
                padding: 0 13px;
                height: 22px;
                line-height: 22px;
                text-align: center;
                background: var(--primary-btn-background-color);
                opacity: 1;
                border-radius: var(--primary-btn-border-radius);
                font-size: 14px;
                font-weight: 500;
                line-height: 22px;
                margin-left: 16px;
                color: var(--primary-btn-color);
                white-space: nowrap;
                flex-shrink: 0;

                &:hover {
                    opacity: var(--hover-opacity);
                }
            }
        }
    }

    .verification-security {
        width: 90%;
        border-radius: var(--card-border-radius);
        background-color: var(--card-unclickable-background-color);
        margin-top: 18px;
        padding: 14px 14px;
        box-sizing: border-box;

        h3 {
            font-size: 14px;
            font-weight: bold;
            line-height: 16px;
            color: var(--text-color);
            margin-bottom: 12px;
        }
        p {
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            color: var(--secondary-text-color);
            opacity: 1;
            padding-right: 20px;
            box-sizing: border-box;
        }
    }

    .account-box {
        width: 90%;
        border-radius: var(--card-border-radius);
        background-color: var(--card-unclickable-background-color);
        margin-top: 18px;
        padding: 22px 12px;
        box-sizing: border-box;
        display: flex;
        align-items: center;

        .account-avatar {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background-color: #efefef;
            margin-right: 11px;
        }
        .account {
            font-size: 14px;
            color: var(--text-color);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            flex-grow: 1;
            margin-right: 10px;
        }

        .more-account {
            color: var(--accent-color);
            cursor: pointer;
            font-size: 14px;
            font-weight: 500;
            text-align: end;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
}

@primary-color: #5177f9;