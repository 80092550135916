@font-face {
    font-family: 'SF Pro Text';
    font-display: auto;
    src: url('../font/SF-Pro-Text-Bold.ttf');
    src: url('../font/SF-Pro-Text-Bold.eot'), url('../font/SF-Pro-Text-Bold.eot?iefix') format('embedded-opentype'),
        url('../font/SF-Pro-Text-Bold.woff') format('woff'), url('../font/SF-Pro-Text-Bold.ttf') format('truetype'),
        url('../font/SF-Pro-Text-Bold.svg#SF Pro Text-Bold, SF Pro Text') format('svg');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'SF Pro Text';
    font-display: auto;
    src: url('../font/SF-Pro-Text-Medium.ttf');
    src: url('../font/SF-Pro-Text-Medium.eot'), url('../font/SF-Pro-Text-Medium.eot?iefix') format('embedded-opentype'),
        url('../font/SF-Pro-Text-Medium.woff') format('woff'), url('../font/SF-Pro-Text-Medium.ttf') format('truetype'),
        url('../font/SF-Pro-Text-Medium.svg#SF Pro Text-Medium, SF Pro Text') format('svg');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'SF Pro Text';
    font-display: auto;
    src: url('../font/SF-Pro-Text-Medium.ttf');
    src: url('../font/SF-Pro-Text-Medium.eot'), url('../font/SF-Pro-Text-Medium.eot?iefix') format('embedded-opentype'),
        url('../font/SF-Pro-Text-Medium.woff') format('woff'), url('../font/SF-Pro-Text-Medium.ttf') format('truetype'),
        url('../font/SF-Pro-Text-Medium.svg#SF Pro Text-Medium, SF Pro Text') format('svg');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'SF Pro Text';
    font-display: auto;
    src: url('../font/SF-Pro-Text-Regular.ttf');
    src: url('../font/SF-Pro-Text-Regular.eot'),
        url('../font/SF-Pro-Text-Regular.eot?iefix') format('embedded-opentype'),
        url('../font/SF-Pro-Text-Regular.woff') format('woff'),
        url('../font/SF-Pro-Text-Regular.ttf') format('truetype'),
        url('../font/SF-Pro-Text-Regular.svg#SF Pro Text-Regular, SF Pro Text') format('svg');
    font-weight: 400;
    font-style: normal;
}

:root {
    --font-family: 'SF Pro Text', 'SF Pro Icons', 'PingFang SC', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
}

body {
    // -webkit-appearance: none;

    // image-rendering: -moz-crisp-edges;
    // image-rendering: -o-crisp-edges;
    // image-rendering: -webkit-optimize-contrast;
    // image-rendering: crisp-edges;
    // -ms-interpolation-mode: nearest-neighbor;
    overflow: hidden;
    color: var(--text-color);
    font-family: var(--font-family);

    font-smooth: antialiased;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
        -webkit-text-fill-color: var(--text-color);
        -webkit-box-shadow: 0 0 0px 100px transparent inset;
        transition: background-color 5000s ease-in-out 0s;
        font-size: 15px;
        caret-color: var(--text-color);
        font-smooth: antialiased;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
}

button,
input,
optgroup,
select,
textarea {
    font-family: var(--font-family);
    font-smooth: antialiased;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

div#root {
    max-width: 600px;
    margin: auto;
    position: relative;
    min-height: 100%;
}

.icon-navigation-back {
    position: absolute;
    font-size: 28px;
    top: 16px;
    left: 16px;
    z-index: 99;
}

.close-iframe {
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 100;
    &.top-hint {
        top: 75px;
    }
}

.ant-btn {
    border: none;
    &:hover {
        border: none;
        opacity: var(--hover-opacity);
    }
    &:active &:focus {
        border: none;
    }
    &:disabled {
        pointer-events: none;
        opacity: var(--disable-opacity);
        span {
            opacity: var(--disable-opacity);
        }
    }
    background: var(--primary-btn-background-color);
    color: var(--primary-btn-color);
    border-radius: var(--primary-btn-border-radius);
    font-size: var(--primary-btn-font-size);
    padding: 3px 15px;
}

.ant-modal-confirm-btns {
    .ant-btn {
        background: var(--primary-btn-background-color);
        color: var(--primary-btn-color);
        border: none;
        padding-left: 18px;
        padding-right: 18px;
        border-radius: var(--primary-btn-border-radius);
        // display: flex;
        // align-items: center;
    }
}

.ant-modal-content {
    background-color: var(--modal-background-color) !important;
    border: none !important;
    border-radius: var(--modal-border-radius) !important;
    .ant-modal-confirm-title {
        color: var(--text-color) !important;
    }
}
.ant-modal-confirm-body .ant-modal-confirm-content {
    color: var(--text-color);
}

.ant-drawer-content-wrapper {
    width: 100vw !important;
    max-width: 600px !important;
    left: calc(50% - calc(var(--vw) * 50));
    box-shadow: none !important;
}

.ant-drawer-content {
    border-radius: var(--modal-border-radius) var(--modal-border-radius) 0 0;
    background-color: var(--background-color-1) !important;
}

.ant-form {
    color: var(--text-color);
}

.ant-form-item-explain-error {
    color: var(--error-color);
}

.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
    border-color: var(--error-color);
}

.ant-message-notice-content {
    font-weight: 500;
    box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    padding-left: 26px;
    padding-right: 26px;
}

body.dark {
    color: var(--text-color);
    position: relative;

    .info-sign {
        .btn-box {
            background-color: var(--card-unclickable-background-color);
        }
        .risk-reminder {
            background-color: var(--card-unclickable-background-color);
        }
    }

    .ant-message-notice-content {
        background: var(--modal-background-color);
        color: var(--text-color);
    }
}

html:not([data-hash='#/login']),
html[data-login-type='phone'],
html[data-login-type='email'] {
    body {
        background: var(--app-background-color) !important;
    }
}

html.dark[data-hash='#/login']:not([data-login-type='phone']):not([data-login-type='email']) {
    @media (min-width: 600px) {
        body {
            &::before {
                display: none !important;
            }
            &::after {
                display: none !important;
            }
            #root {
                background: none !important;
            }
        }
    }
}

.hidden {
    display: none !important;
}

.pre-wrap {
    white-space: pre-wrap;
}

.hide-hover {
    --hover-opacity: 1 !important;
}

::selection {
    color: #ffffff;
    background-color: var(--accent-color);
}

//验证码输入框
.react-input-code {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .input-code-item {
        width: 44px;
        height: 44px;
        background-color: var(--input-background-color);
        padding: 0;
        font-size: 20px;
        text-align: center;
        margin-right: 6px;
        margin-left: 6px;
        text-transform: uppercase;
        color: var(--text-color);
        border: transparent;
        border-radius: 12px;
        background-clip: padding-box;
        // user-select: none;
        caret-color: var(--text-color); //光标颜色
        &:focus {
            appearance: none;
            outline: 0;
        }
        &:not(:placeholder-shown) {
            appearance: none;
            outline: 0;
        }
    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 60px var(--input-background-color) inset !important;
        -webkit-text-fill-color: var(--text-color) !important; //设置填充字体颜色
    }

    .input-code-mask {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;
    }
}

.primary-antd-btn {
    width: 100%;
    height: 47px;
    line-height: 22px;
    border-radius: var(--primary-btn-border-radius);
    color: var(--primary-btn-color);
    background: var(--primary-btn-background-color);
    font-size: var(--primary-btn-font-size);
    border: none;
    outline: none;
    &:focus,
    &:active {
        background: var(--primary-btn-background-color);
        color: var(--primary-btn-color);
    }
    &:hover {
        opacity: var(--hover-opacity);
        background: var(--primary-btn-background-color);
        color: var(--primary-btn-color);
    }
    &:disabled {
        opacity: var(--disable-opacity);
        background: var(--primary-btn-background-color);
        color: var(--primary-btn-color);
        span {
            opacity: var(--disable-opacity);
        }
    }

    &.danger {
        background: var(--error-color) !important;
        color: white !important;
    }

    &.secondary {
        background: var(--secondary-btn-background-color);
        color: var(--secondary-btn-color);
    }
    span {
        font-weight: 600;
    }
}

.accent-antd-btn {
    width: 100%;
    height: 47px;
    line-height: 22px;
    border-radius: var(--primary-btn-border-radius);
    color: white;
    background-color: var(--accent-color);
    font-size: var(--primary-btn-font-size);
    border: none;
    outline: none;
    &:focus,
    &:active {
        background-color: var(--accent-color);
        color: white;
    }
    &:hover {
        opacity: var(--hover-opacity);
        background-color: var(--accent-color);
        color: white;
    }
    &:disabled {
        opacity: var(--disable-opacity);
        background-color: var(--accent-color);
        color: white;
    }
    span {
        font-weight: 600;
    }
}

.theme-font-icon {
    color: var(--text-color);
    &:hover {
        opacity: var(--hover-opacity);
    }
}

.no-number {
    -moz-appearance: textfield !important;
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused,
.ant-input:focus,
.ant-radio-input:focus + .ant-radio-inner,
.ant-input-focused {
    box-shadow: none;
}

.ant-radio-inner {
    background: none;
    border-width: 2px !important;
    border-color: var(--border-color-1) !important;
    &:after {
        width: 14px;
        height: 14px;
        margin-top: -7px;
        margin-left: -7px;
    }
}
.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
    border-color: var(--accent-color) !important;
}

.ant-message-notice-content {
    padding: 0;
    border-radius: var(--primary-btn-border-radius);
    overflow: hidden;
    .ant-message-custom-content {
        padding: 10px 16px;
        color: #fff;
    }
}
.ant-message-error {
    background: var(--error-color);
    .anticon {
        color: #fff;
    }
}
.ant-message-success {
    background: var(--green-color);
    .anticon {
        color: #fff;
    }
}

.color-scheme-dark {
    color-scheme: dark;
    iframe {
        color-scheme: none;
    }
}

.m-warning-modal {
    .ant-modal-confirm-btns {
        display: none;
    }
    .content {
        margin-top: 26px;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        color: var(--text-color);
        text-align: center;
    }
    .anticon-exclamation-circle {
        display: none;
    }
    .anticon-close {
        color: var(--secondary-text-color);
    }
    .footer-btns {
        display: flex;
        justify-content: flex-end;
        column-gap: 18px;
        margin-top: 30px;
        .ant-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            flex: 1;
            height: 47px;
            text-align: center;

            font-size: 17px;
            font-weight: 600;
            line-height: 22px;
            color: var(--background-color);
            &:first-child {
                background-color: var(--secondary-btn-background-color);
                color: var(--secondary-btn-color);
            }
        }
        .confirm-btn:hover,
        .confirm-btn:focus,
        .confirm-btn:active {
            background-color: var(--primary-btn-background-color);
            color: var(--primary-btn-color);
        }
        .ant-btn {
            box-shadow: none !important;
            &::after {
                display: none !important;
            }
        }
    }
}

.ant-btn {
    font-weight: 600 !important;
}
.ant-input {
    padding-left: 14px;
    padding-right: 14px;
    &:focus {
        border: 1px solid var(--accent-color) !important;
        border-radius: var(--primary-btn-border-radius);
    }
}

.ant-input,
.input_email,
.ant-input-number-input,
.account-input-box input {
    border-radius: var(--primary-btn-border-radius);
    transition: none !important;
    border: 1px solid var(--input-background-color);
    &:focus {
        border: 1px solid var(--accent-color) !important;
        border-radius: var(--primary-btn-border-radius);
    }
}

.ant-btn-primary {
    box-shadow: none !important;
}
.ant-tabs {
    .ant-tabs-tab {
        .ant-tabs-tab-btn {
            font-size: 16px;
        }
    }
    .ant-tabs-ink-bar {
        display: none;
    }
}
.more-text-btn {
    color: var(--accent-color) !important;
}

input::placeholder {
    color: var(--input-placeholder-color) !important;
}

.ant-btn-loading {
    opacity: var(--disable-opacity);
    span {
        opacity: var(--disable-opacity);
    }

    &::before {
        display: none !important;
    }
    .ant-btn-loading-icon {
        opacity: 1;
        position: relative;
        background-image: var(--loading-icon);
        background-size: 19px 19px;
        height: 19px !important;
        width: 19px !important;
        position: relative;
        top: 3px;
        left: -3px;
        transition: initial !important;
        animation: none !important;
        .anticon {
            display: none;
        }
    }
}

.ant-modal {
    max-width: 340px;
    width: 90%;
    .ant-modal-body {
        padding: 18px 18px;
    }
    .ant-modal-close-x {
        background: var(--close-icon);
        background-repeat: no-repeat;
        background-position: 12px 12px;
        .anticon {
            display: none;
        }
    }

    .ant-btn {
        span {
            font-size: 14px;
        }
    }
}

input {
    caret-color: var(--accent-color);
}

 
.theme-light{
    input:-webkit-autofill {
        // -webkit-box-shadow: 1px 1px -0px 10px var(--input-background-color) inset !important;
        -webkit-text-fill-color: var(--text-color,#000) !important;
    }
}
