.particle-pc-drawer {
    .select-account {
        padding-top: 52px;
    }
}
.select-account {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 38px;
    .close-icon {
        position: absolute;
        right: 10px;
        top: 10px;
    }

    .account-btn {
        width: 100%;
        height: 47px;
        line-height: 47px;
        margin-bottom: 15px;
        font-size: var(--primary-btn-font-size);
        border-radius: var(--primary-btn-border-radius);
        color: var(--primary-btn-color);
        background: var(--primary-btn-background-color);
        border: none;
        outline: none;
        line-height: 22px;

        &:hover {
            opacity: var(--hover-opacity);
        }
    }

    .select-account-title {
        font-size: 20px;
        line-height: 1;
        color: var(--text-color);
        font-weight: 600;
        padding-bottom: 24px;
        padding-top: 24px;
    }
}

@primary-color: #5177f9;