.error-box {
    text-align: center;
    width: 100%;
    height: var(--doc-height);
    padding-top: 25vh;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .error-gif {
        margin-bottom: 24px;
        width: 70px;
        height: 70px;
    }
    .error-msg {
        color: var(--text-color);
        margin: 0 10px;
        font-size: 18px;
    }
    .return-button {
        margin-top: 130px;
        min-width: 173px;
        max-width: 300px;
        padding: 0 16px;
        height: 47px;
        opacity: 1;
        font-size: var(--primary-btn-font-size);
        border-radius: var(--primary-btn-border-radius);
        background: var(--primary-btn-background-color);
        font-weight: normal;
        color: var(--primary-btn-color);
    }
}

@primary-color: #5177f9;