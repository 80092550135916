.about-mp-container {
    padding: 0 18px;
    overflow: auto;
    height: var(--doc-height);
    .space-container {
        padding: 50px 0;
        width: 100%;
        .master-title {
            text-align: center;
        }
        .ant-typography {
            color: var(--text-color) !important;
        }
        .content {
            font-size: 14px;
            color: var(--secondary-text-color) !important;
        }
    }
}

@primary-color: #5177f9;