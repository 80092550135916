.risk-typography {
    background: var(--card-unclickable-background-color);
    border-color: var(--card-unclickable-border-color);
    border-radius: var(--card-border-radius);
    border-width: 1px;
    border-style: solid;
    margin: 13px 18px 5px 18px;
    width: calc(100% - 36px);
    max-height: 160px;
    overflow-y: auto;
    &.hide {
        display: none;
    }

    .risk-typography-title {
        margin: 12px 14px 4px;
        font-size: 14px;
        font-weight: bold;
        color: var(--text-color);
    }

    .ant-tree-switcher {
        display: none;
    }

    .risk-tree {
        background: initial;
        color: var(--text-color);
        font-size: 14px;
        padding: 10px;

        .risk-icon {
            width: 24px;
            height: 24px;
            margin-right: 4px;
            background-size: 19px 19px;
            background-repeat: no-repeat;
            background-position-y: 2px;
            &.danger {
                background-image: var(--icon-danger);
            }
            &.warning {
                background-image: var(--icon-warning);
            }
        }
        .risk-dot {
            width: 6px;
            height: 6px;
            border-radius: 50%;
            margin: 9px;
            &.danger {
                background: var(--error-color);
            }
            &.warning {
                background: var(--warning-color);
            }
        }
    }
}

@primary-color: #5177f9;