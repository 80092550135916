.info-sign {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 290px;

    .pending-warning-modal {
        .ant-modal-confirm-btns {
            display: none;
        }
        .content {
            margin-top: 26px;
            font-size: 16px;
            font-weight: 500;
            line-height: 20px;
            color: var(--text-color);
            text-align: center;
        }
        .anticon-exclamation-circle {
            display: none;
        }
        .anticon-close {
            color: var(--secondary-text-color);
        }
        .footer-btns {
            display: flex;
            justify-content: flex-end;
            column-gap: 18px;
            margin-top: 30px;
            .ant-btn {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 16px;
                flex: 1;
                height: 47px;
                text-align: center;

                font-size: 17px;
                font-weight: 600;
                line-height: 22px;
                color: var(--background-color);
                border: none;
                border-radius: var(--primary-btn-border-radius);
                &.cancel-btn {
                    background-color: var(--secondary-btn-background-color);
                    color: var(--secondary-btn-color);
                }
                &.process-now-btn {
                    background-color: var(--primary-btn-background-color);
                    color: var(--primary-btn-color);
                }
            }
            .ant-btn:hover,
            .ant-btn:focus,
            .ant-btn:active {
                background-color: var(--primary-btn-background-color);
                color: var(--primary-btn-color);
            }
        }
    }
    .continue-btn {
        &:hover {
            background-color: var(--secondary-btn-background-color) !important;
            color: var(--secondary-btn-color) !important;
        }
    }
    .has-payment-password {
        width: calc(100 * var(--vw));
        height: var(--set-payment-password-bar-height);
        display: flex;
        align-items: center;
        background-color: var(--tips-background-color);
        padding: 0 16px;
        box-sizing: border-box;
        opacity: 1;
        position: fixed;
        top: 0;
        z-index: 100;

        .has-payment-password-icon {
            width: 18px;
            height: 18px;
            background-image: var(--icon-warning);
            background-size: 100% auto;
            background-repeat: no-repeat;
            background-color: white;
            border-radius: 50%;
            overflow: hidden;
            border: 1px solid #f7af5d;
        }
        .has-payment-password-tip {
            flex: 1;
            margin: 0 8px;
            font-size: 12px;
            font-weight: 400;
            line-height: 15px;
            color: var(--text-color);
            text-align: left;
            @media screen and (max-width: 350px) {
                letter-spacing: -1px;
            }
        }
        .has-payment-password-set {
            min-width: 44px;
            height: 22px;
            background: var(--primary-btn-background-color);
            padding: 0px 8px;
            border-radius: var(--primary-btn-border-radius);
            font-size: 14px;
            font-weight: 500;
            line-height: 22px;
            color: var(--primary-btn-color);
            text-align: center;
            cursor: pointer;
            &:hover {
                opacity: var(--hover-opacity);
            }
        }
    }

    .ant-tabs {
        min-width: 230px;
        width: calc((var(--vw) * 100) - 36px);
    }
    .ant-tabs-nav {
        .ant-tabs-tab {
            padding-bottom: 4px;
            color: var(--secondary-text-color);
            &.ant-tabs-tab-active {
                .ant-tabs-tab-btn {
                    color: var(--text-color);
                }
            }
        }

        &:before {
            display: none;
        }
        .ant-tabs-nav-wrap {
            .ant-tabs-ink-bar {
                background: var(--text-color);
            }
        }
    }
    .scroll-part {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: calc(var(--doc-height) - var(--sign-bottom-menu-height) - var(--risk-bar-height)); // btn-box height
        overflow: auto;
        position: relative;
        padding-bottom: 20px;

        &.no-password-tip {
            margin-top: var(--set-payment-password-bar-height); // set payment password notification bar height
            height: calc(
                var(--doc-height) - var(--sign-bottom-menu-height) - var(--set-payment-password-bar-height) -
                    var(--risk-bar-height)
            ) !important;
        }
        .top-menu-list {
            position: absolute;
            z-index: 99;
            top: 16px;
            left: 15px;
            display: flex;
            justify-content: flex-start;
            column-gap: 5px;
            .item {
                cursor: pointer;
                .anticon {
                    font-size: 26px;
                    color: var(--text-color);
                }
                .ant-badge-dot {
                    width: 6px;
                    height: 6px;
                    top: 3px;
                    right: 3px;
                    box-shadow: none;
                    border: none;
                }
            }
        }
        .menu-entry {
            position: absolute;
            z-index: 99;
            top: 16px;
            left: 15px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .menu-icon {
                cursor: pointer;
            }
            .wallet-entry {
                margin-left: 8px;
                font-size: 28px;
            }
            .ant-popover {
                padding: 0;
                .ant-popover-arrow {
                    display: none;
                }
                .ant-popover-inner {
                    border-radius: var(--primary-btn-border-radius);
                }
                .ant-popover-inner {
                    background: var(--modal-background-color);
                }
                .menu-list {
                    display: flex;
                    flex-direction: column;
                    .item {
                        position: relative;
                        white-space: nowrap;
                        font-size: 14px;
                        line-height: 2;
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        .ant-badge {
                            font-size: 14px;
                            color: var(--text-color);
                            .ant-badge-dot {
                                top: -1px;
                                right: -4px;
                            }
                        }
                    }
                }
            }
            .ant-badge {
                display: flex;
                align-items: center;
                column-gap: 8px;
                .ant-badge-dot {
                    width: 6px;
                    height: 6px;
                    top: 3px;
                    right: 3px;
                    box-shadow: none;
                    border: none;
                }
            }
        }
        .menu-popover-overlay {
            .ant-popover-inner-content {
                padding: 0;
                .menu-list {
                    padding-top: 6px;
                    padding-bottom: 6px;
                    .item {
                        font-size: 14px;
                        font-weight: 500;
                        color: #000000;
                        height: 45px;
                        line-height: 45px;
                        border-bottom: 1px solid var(--keyword-border-color);
                        padding: 0 17px;
                        &:last-child {
                            border-bottom: none;
                        }
                        .anticon {
                            color: var(--text-color);
                            font-size: 26px;
                        }
                    }
                }
            }
        }
        .info-title {
            padding-top: 45px;
            margin-top: 10px;
            margin-bottom: 2px;
            font-size: 12px;
            height: 19px;
            font-weight: 400;
            color: var(--text-color);
            text-align: center;
            // display: flex;
            // align-items: center;
            img {
                width: 13px;
                height: 13px;
                margin-right: 4px;
                margin-bottom: 3px;
            }
        }
        .info-address {
            background: var(--tag-background-color);
            border-radius: var(--primary-btn-border-radius);
            margin-top: 24px;
            color: var(--secondary-text-color);
            display: flex;
            align-items: center;
            cursor: pointer;
            height: 23px;
            font-size: 12px;
            padding: 0 5px 0 8px;
            line-height: 23px;
            flex-shrink: 0;
            .copy-icon {
                margin-left: 5px;
                width: 14px;
                height: 100%;
                position: relative;
                .anticon {
                    position: absolute;
                    top: 5px;
                }
            }
        }
        .info-request {
            width: 100%;
            text-align: center;
            padding-top: 13px;
            font-size: 20px;
            font-weight: 600;
            color: var(--text-color);
            position: absolute;
            z-index: 50;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 4px;
            // .aa-tag {
            //     margin-right: 5px;
            //     font-size: 12px;
            //     border-radius: 10px;
            //     padding: 0 4px;
            //     font-weight: bold;
            //     color: var(--background-color);
            //     background-color: var(--text-color);
            // }
            .aa-icon {
                display: flex;
                align-items: center;
                .ant-image {
                    width: 20px;
                    height: 20px;
                    position: relative;
                    .ant-image-img {
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                    }
                }
            }
        }
        .info-des {
            text-align: center;
            margin: 10px 0;
            max-width: 300px;
            height: 40px;
            font-size: 14px;
            font-weight: 400;
            line-height: 1.2;
            color: var(--secondary-text-color);
        }
        .apart-line {
            width: 100%;
            border-top: 1px solid var(--card-unclickable-border-color);
            display: none;
        }
        .balance-change {
            padding: 15px 12px;
            width: calc((var(--vw) * 100) - 36px);
            min-width: 230px;
            border-width: 1px;
            border-style: solid;
            border-radius: var(--card-border-radius);
            border: none;
            background: var(--card-unclickable-background-color);
            .title {
                font-size: 14px;
                font-weight: 500;
                color: var(--text-color);
                padding-bottom: 1px;
            }
            .change-body {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .change-title {
                    font-size: 14px;
                    font-weight: 400;
                    color: var(--text-color);
                    display: flex;
                    margin-top: 10px;
                    justify-content: space-between;
                    .change-val {
                        font-size: 14px;
                        font-weight: 600;
                        color: var(--green-color);
                    }
                }
                .mt20 {
                    margin-top: 20px;
                }
                .message-text {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 24px;
                    color: var(--text-color);
                }
            }
        }

        .from-to {
            // width: 342px;
            width: calc((var(--vw) * 100) - 36px);
            min-width: 230px;
            margin-top: 15px;
            border: 1px solid;
            padding: 20px 12px;
            font-size: 14px;
            font-weight: 400;
            // line-height: 16px;
            color: var(--text-color);
            border-radius: var(--card-border-radius);
            border: none;
            background: var(--card-unclickable-background-color);
            .address-item {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
            .mt10 {
                margin-top: 10px;
            }
        }

        .no-gas-fee {
            width: calc((var(--vw) * 100) - 36px);
            min-width: 230px;
            margin-top: 15px;
            // border: 1px solid var(--error-color);
            background: rgba(234, 67, 53, 0.09);
            padding: 20px 12px;
            font-size: 14px;
            font-weight: 400;
            // line-height: 16px;
            color: var(--text-color);
            border-radius: var(--card-border-radius);
            img {
                width: 18.5px;
                height: 18.5px;
                margin-top: 3px;
                margin-right: 10px;
            }
            .no-title {
                font-weight: 600;
            }
            .no-warning {
                line-height: 15px;
                display: flex;
                margin-top: 10px;
                padding-bottom: 9px;
                border-bottom: 1px solid var(--card-divider-color);
            }
            .data-title {
                margin-top: 15px;
                font-weight: bold;
            }
            .data-item {
                line-height: 14px;
                margin-top: 10px;
            }
        }

        .net-fee {
            padding: 15px 12px;
            // width: 342px;
            width: calc((var(--vw) * 100) - 36px);
            min-width: 230px;
            background-color: var(--card-unclickable-background-color);
            border-width: 1px;
            border-style: solid;
            border-radius: var(--card-border-radius);
            margin-top: 15px;
            cursor: pointer;
            border: none;
            // &:hover {
            //     border-color: var(--card-hover-border-color);
            // }
            // &.solana {
            //     background: var(--card-unclickable-background-color);
            //     // border-color: var(--card-unclickable-border-color);
            //     cursor: unset;
            //     &:hover {
            //         border-color: var(--card-unclickable-border-color);
            //     }
            // }
            .title {
                font-size: 14px;
                color: var(--text-color);
                font-weight: 700;
                display: flex;
                justify-content: space-between;
                .fee-val {
                    font-size: 16px;
                    font-weight: 400;
                    color: var(--text-color);
                }
            }

            .evm-fee {
                font-size: 14px;
                font-weight: 400;
                // line-height: 19px;
                color: var(--text-color);
                .fee-title {
                    font-weight: 500;
                    span {
                        font-size: 14px;
                        font-weight: 500;
                        // line-height: 19px;
                        color: var(--secondary-text-color);
                    }
                }
                .fee-row {
                    margin-top: 8px;
                    display: flex;
                    justify-content: space-between;
                    // flex-direction: column;
                    // align-items: center;
                    img {
                        // position: absolute;
                        // float: right;
                        width: 9.19px;
                        // margin-top: 6px;
                    }
                    .approximately {
                        font-weight: 400;
                        color: var(--secondary-text-color);
                        // line-height: 19px;
                    }
                    .fee-standard {
                        width: 70px;
                        font-weight: 600;
                        font-weight: normal;
                        // float: right;
                        margin-right: 10px;
                    }
                    .fee-time {
                        width: 70px;
                        font-weight: 600;
                        color: var(--green-color);
                        // float: right;
                        margin-right: 19.19px;
                    }
                    .row-right {
                        display: flex;
                        align-items: center;
                        .right-icon {
                            width: 9.19px;
                            margin-left: 15px;
                        }
                    }
                    .fee-right {
                        display: flex;
                        text-align: right;
                        .arrow-right-icon {
                            color: var(--text-color);
                            svg {
                                width: 13px;
                                height: 13px;
                            }
                        }
                    }
                    .right-time {
                        font-size: 12px;
                        font-weight: bold;
                        // line-height: 19px;
                        color: var(--green-color);
                        margin-right: 55px;
                    }
                }
            }
        }

        .total {
            padding: 15px 12px;
            // width: 342px;
            width: calc((var(--vw) * 100) - 36px);
            min-width: 230px;
            border: 1px solid var(--card-unclickable-border-color);
            border-radius: var(--card-border-radius);
            font-size: 14px;
            font-weight: 400;
            // line-height: 19px;
            color: var(--text-color);
            margin-top: 15px;
            .total-title {
                font-weight: bold;
            }
            .mt8 {
                margin-top: 8px;
            }
            .total-content {
                span {
                    word-break: break-all;
                    font-weight: 400;
                    color: var(--secondary-text-color);
                    // line-height: 19px;
                }
            }
        }

        .show-btn {
            margin-top: 4px;
            font-size: 14px;
            font-weight: 600;
        }

        .program-details {
            text-align: center;
            margin-top: 30px;
            font-weight: 600;
            color: var(--text-color);
        }

        .inner-instruction {
            margin-bottom: 15px;
            // padding: 15px 10px 20px 10px;
            // width: 342px;
            // min-height: 187px;
            width: calc((var(--vw) * 100) - 36px);
            min-width: 230px;
            .inner-content {
                .content-item {
                    margin-top: 0;
                    .item {
                        padding: 13px 11px;
                        border-color: var(--card-unclickable-border-color) !important;
                        background-color: var(--card-unclickable-background-color) !important;
                        border-width: 1px;
                        border-style: solid;
                        border-radius: var(--card-border-radius);
                        border: none;
                        .item-0 {
                            font-size: 14px;
                            font-weight: 600;
                            line-height: 24px;
                            color: var(--text-color);
                            // padding-bottom: 12px;
                            word-break: break-all;
                        }
                        .mt10 {
                            margin-top: 10px;
                        }
                        .mt15 {
                            margin-top: 15px;
                        }
                        .item-1 {
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 19px;
                            color: var(--text-color);
                            display: flex;
                            justify-content: space-between;
                            span {
                                max-width: 220px;
                                text-align: right;
                                font-size: 14px;
                                font-weight: 400;
                                line-height: 19px;
                                color: var(--secondary-text-color);
                            }
                            .data {
                                max-width: calc(85 * var(--vw));
                                text-align: left;
                                font-size: 14px;
                                font-weight: 400;
                                line-height: 19px;
                                color: var(--secondary-text-color);
                                word-wrap: break-word;
                            }
                        }
                    }
                }
            }
        }

        .sign-message {
            padding: 0;
            margin-top: 18px;
            // width: 342px;
            // min-height: 116px;
            width: calc((var(--vw) * 100) - 36px);
            min-width: 230px;
            // max-height: calc(100% - 50px);
            background-color: var(--card-unclickable-background-color);
            border-radius: var(--card-border-radius);
            border: none;
            .s-row {
                display: flex;
                color: var(--text-color);
                margin-top: 6px;
                margin-bottom: 6px;
                line-height: 1.3;
                font-size: 14px;
                &[data-index='0'] {
                    :after {
                        display: none;
                    }
                }
                [data-type='title'],
                [data-type='index'],
                .label[data-type='title'],
                .title[data-type='title'],
                .value[data-type='index'] {
                    font-size: 14px;
                    position: relative;
                    font-weight: bold;
                    color: var(--text-color);
                }
                .label {
                    font-size: 14px;
                    font-weight: 400;
                    color: var(--secondary-text-color);
                }
                .value {
                    flex: 1;
                    word-break: break-all;
                    font-size: 14px;
                    font-weight: 400;
                    color: var(--secondary-text-color);
                    overflow: hidden;
                }
            }
            .message {
                font-size: 14px;
                color: var(--secondary-text-color);
                font-weight: 400;
                line-height: 24px;
                overflow: hidden;
                word-wrap: break-word;
                position: relative;
                max-height: calc(var(--doc-height) - 300px);
                overflow: auto;
                padding: 8px 12px;
                .personal-message {
                    position: relative;
                }
                pre {
                    padding-bottom: 2px;
                }
                &.no-password-tip {
                    max-height: calc(
                        var(--doc-height) - 300px - var(--set-payment-password-bar-height) - var(--risk-bar-height)
                    ) !important;
                }
            }
        }
    }

    .btn-box {
        min-width: 290px;
        width: 100%;
        position: fixed;
        bottom: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-shadow: 0px -2px 3px 1px rgba(0, 0, 0, 0.08);
        z-index: 200;
        height: var(--sign-bottom-menu-height);
        max-width: calc(100 * var(--vw));
        background: var(--theme-background-color);
        & > div {
            display: flex;
            width: calc((var(--vw) * 100) - 36px);
            gap: 18px;
        }
        .footer-box {
            margin-top: 10px;
        }
        .btn-cancel {
            padding: 0;
            margin-top: 10px;
            flex: 1;
            max-width: calc(300 * var(--vw));
            height: 47px;
            background: var(--secondary-btn-background-color);
            border-radius: var(--primary-btn-border-radius);
            font-size: 17px;
            font-weight: 600;
            color: var(--secondary-btn-color);
            text-align: center;
            border: none;
            &:hover {
                opacity: var(--hover-opacity);
            }

            span {
                font-weight: 600;
            }
        }
        .btn-approve {
            padding: 0;
            margin-top: 10px;
            flex: 1;
            max-width: calc(300 * var(--vw));
            height: 47px;
            background: var(--primary-btn-background-color);
            font-size: var(--primary-btn-font-size);
            border-radius: var(--primary-btn-border-radius);
            font-weight: 600;
            color: var(--primary-btn-color);
            // margin-left: 18px;
            text-align: center;
            border: none;
            &:hover {
                opacity: var(--hover-opacity);
            }
            img {
                width: 12.75px;
                height: 18.29px;
                margin-right: 12px;
            }
        }
    }

    &.info-sign-erc20_transfer,
    &.info-sign-erc1155_transfer,
    &.info-sign-erc20_approve,
    &.info-sign-native_transfer,
    &.info-sign-erc721_transfer,
    &.info-sign-seaport_cancel_order,
    &.info-sign-seaport_nft_listing,
    &.info-sign-seaport_fulfill_order {
        .fee-row {
            align-items: center;
        }
        .item4 {
            .flex-sp-row {
                flex-wrap: wrap;
                .left {
                    margin-bottom: 10px;
                    font-weight: 600;
                    color: var(--text-color);
                }
                .right {
                    color: var(--secondary-text-color);
                }
                .gas-warning {
                    display: flex;
                    align-items: center;
                    line-height: 1.3;
                    // height: 58px;
                    color: var(--secondary-text-color);
                    span {
                        line-height: 1.4;
                    }
                    img {
                        width: 19px;
                        height: 19px;
                        flex: 1;
                        margin-right: 10px;
                        margin-top: 4px;
                        align-self: flex-start;
                    }
                }
            }
        }

        .info-request {
            display: none;
        }
        .info-title {
            padding: 0;
            margin: 18px 0 0;
        }
        .apart-line {
            display: none;
        }
        .info-address {
            margin-bottom: 10px;
            margin-top: 10px;
        }
        .info-des {
            display: none;
        }
        .transfer-content {
            width: 100%;
            min-width: 230px;
            width: calc((var(--vw) * 100) - 36px);
            margin: auto;
            margin-top: 18px;
            .mg-bottom-15 {
                margin-bottom: 15px;
            }
            .flex-sp-row {
                display: flex;
                justify-content: space-between;
            }
            .less-box {
                background-color: var(--card-unclickable-background-color);
                border-radius: var(--card-border-radius);
                padding: 13px;
                padding-bottom: 3px;
                .pn-row {
                    width: 100%;
                }
                .groups {
                    > .item {
                        border-bottom: 1px solid var(--card-divider-color);
                        padding-bottom: 16px;
                        padding-top: 14px;
                        &:last-child {
                            border-bottom: none;
                        }
                        // display: flex;
                        // justify-content: space-between;
                        &.item1 {
                            .row1 {
                                .left {
                                    font-size: 18px;
                                    font-weight: 800;
                                    line-height: 19px;
                                    color: var(--text-color);
                                }
                                .right {
                                    font-size: 11px;
                                    font-weight: 500;
                                    line-height: 14px;
                                    color: var(--text-color);
                                }
                            }
                            .row-erc20-approve-content {
                                .row-ac1 {
                                    font-size: 22px;
                                    font-weight: 500;
                                    line-height: 14px;
                                    color: var(--text-color);
                                    display: flex;
                                    justify-content: flex-start;
                                    align-items: center;
                                    margin-top: 20px;
                                    margin-bottom: 10px;
                                    .token-icon {
                                        width: 32px;
                                        height: 32px;
                                        min-width: 32px;
                                        object-fit: cover;
                                        margin-right: 6px;
                                        border-radius: 100%;
                                        overflow: hidden;
                                    }
                                    .amount {
                                        word-wrap: break-word;
                                        word-break: break-all;
                                        overflow-wrap: break-word;
                                        line-height: 1.2;
                                    }
                                }
                                .row-ac2 {
                                    font-size: 12px;
                                    font-weight: 500;
                                    line-height: 12px;
                                    color: var(--accent-color);
                                    display: flex;
                                    justify-content: flex-start;
                                    align-items: center;
                                    cursor: pointer;
                                    .icon {
                                        width: 12px;
                                        height: 12px;
                                        margin-left: 6px;
                                    }
                                }
                                .row-ac3 {
                                    font-size: 12px;
                                    font-weight: 500;
                                    line-height: 1.4;
                                    color: var(--text-color);
                                    margin-top: 14px;
                                }
                            }
                            .row2 {
                                align-items: flex-start;
                                margin-top: 20px;
                                .left {
                                    display: flex;
                                    align-items: center;
                                    .icon {
                                        width: 32px;
                                        height: 32px;
                                        border-radius: 100%;
                                        overflow: hidden;
                                        margin-right: 10px;
                                        img {
                                            width: 100%;
                                            height: 100%;
                                            object-fit: cover;
                                            border-radius: 100%;
                                        }
                                    }
                                }
                                .right {
                                    font-size: 14px;
                                    font-weight: 400;
                                    line-height: 14px;
                                    color: var(--secondary-text-color);
                                    flex: 1;
                                    display: flex;
                                    flex-wrap: wrap;
                                    overflow-x: auto;
                                    .amount {
                                        height: 32px;
                                        line-height: 32px;
                                        align-items: center;
                                        flex: 1;
                                        display: flex;
                                        justify-content: flex-start;
                                        align-items: center;

                                        // // 溢出隐藏
                                        // overflow: hidden;
                                        // // 文字不换行
                                        // white-space: nowrap;
                                        // // 文字超出宽度用省略号显示
                                        // text-overflow: ellipsis;
                                        .change-val {
                                            font-size: 22px;
                                            font-weight: 500;
                                            color: var(--text-color);
                                            margin-right: 4px;
                                            line-height: 32px;
                                            white-space: nowrap;
                                        }
                                        .symbol {
                                            position: relative;
                                            // top: 1px;
                                            color: var(--text-color);
                                            line-height: 32px;
                                            font-size: 22px;
                                            font-weight: 500;
                                            margin-left: 2px;
                                            white-space: nowrap;
                                        }
                                    }
                                    .amount-usd {
                                        height: 32px;
                                        line-height: 32px;
                                    }
                                }
                                &[data-feed-line='true'] {
                                    // pass
                                }
                            }
                            .row3 {
                                justify-content: flex-start;
                                align-items: center;
                                margin-top: 20px;
                                .left {
                                    width: 32px;
                                    height: 32px;
                                    margin-right: 10px;
                                    img {
                                        width: 100%;
                                        height: 100%;
                                        object-fit: contain;
                                    }
                                }
                                .right {
                                    flex: 1;
                                    font-size: 22px;
                                    line-height: 1.3;
                                    font-weight: 500;
                                    color: var(--text-color);
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 2;
                                    -webkit-box-orient: vertical;
                                    .name {
                                        line-height: 1.2;
                                    }
                                }
                            }
                            .row4 {
                                font-size: 14px;
                                font-weight: 500;
                                color: var(--accent-color);
                                margin-top: 10px;
                            }
                            .row5 {
                                font-size: 14px;
                                font-weight: 500;
                                color: var(--text-color);
                                margin-top: 4px;
                            }
                            .row6 {
                                font-size: 14px;
                                font-weight: 500;
                                color: var(--text-color);
                                margin-top: 4px;
                            }
                        }
                        &.item2-0 {
                            font-size: 14px;
                            font-weight: 500;
                            line-height: 16px;
                            color: var(--text-color);
                            padding-top: 24px;
                            padding-bottom: 24px;
                        }
                        &.item2 {
                            .flex-sp-row {
                                height: 44px;
                            }
                        }
                        &.item2 {
                            padding-top: 24px;
                            padding-bottom: 22px;
                            .left,
                            .right {
                                .pn-row1 {
                                    font-size: 12px;
                                    font-weight: 400;
                                    line-height: 14px;
                                    color: var(--text-color);
                                }
                                .pn-row2 {
                                    font-size: 14px;
                                    font-weight: 500;
                                    line-height: 16px;
                                    color: var(--text-color);
                                }
                            }
                            .right {
                                text-align: right;
                            }
                            .pn-row2 {
                                margin-top: 10px;
                            }
                            .middle {
                                width: 34px;
                                // img {
                                //   width: 100%;
                                //   height: 13px;
                                //   object-fit: cover;
                                // }
                                position: relative;
                                .icon {
                                    width: 100%;
                                    height: 13px;
                                    position: absolute;
                                    top: 50%;
                                    left: 50%;
                                    transform: translate(-50%, -50%);
                                    display: flex;
                                    align-items: center;
                                }
                            }
                        }
                        &.item5 {
                            color: var(--text-color);
                            font-size: 14px;
                            font-weight: 500;
                            line-height: 30px;
                            .price-sub {
                                padding-left: 16px;
                                position: relative;
                                font-weight: 400;
                                font-size: 12px;
                                line-height: 24px;
                                &:before {
                                    content: '';
                                    position: absolute;
                                    left: 4px;
                                    top: 50%;
                                    transform: translateY(-50%);
                                    width: 5px;
                                    height: 5px;
                                    border-radius: 100%;
                                    background: var(--accent-color);
                                }
                            }
                        }
                    }
                }
            }
            .fold-content {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 13px;
                font-weight: 500;
                line-height: 30px;
                color: var(--accent-color);
                height: 30px;
                margin-top: 20px;
                margin-bottom: 5px;
                img {
                    width: 10px;
                    height: 6px;
                    margin-left: 5px;
                }
                .wrap {
                    & > div {
                        display: flex;
                        align-items: center;
                        gap: 6px;
                    }
                }
                .arrow-icon {
                    color: var(--accent-color);
                    svg {
                        width: 11px;
                        height: 11px;
                    }
                }
                .fold {
                    cursor: pointer;
                }
                .unfold {
                    cursor: pointer;
                    .arrow-icon {
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }
}

.edit-approve-amount-modal {
    .ant-modal-content {
        overflow: hidden;
        background-color: var(--modal-background-color) !important;
    }
    .ant-modal-header {
        border: none;
        background-color: var(--modal-background-color);
        .ant-modal-title {
            font-size: 24px;
            line-height: 19px;
            color: var(--text-color);
            text-align: center;
            padding-top: 40px;
        }
    }
    .ant-modal-body {
        padding-bottom: 16px;
    }
    .ant-form {
        display: flex;
        align-items: flex-start;
        width: 100%;
        .ant-row {
            flex: 1;
        }
        .ant-form-item-control-input-content {
            .ant-input {
                // flex: 1;
                height: 40px;
                background: var(--input-background-color-3);
                border: 1px solid var(--input-border-color);
                color: var(--text-color);
            }
            .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
            .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
                background: var(--input-background-color-3);
            }
        }
        .icon {
            width: 30px;
            min-width: 30px;
            height: 30px;
            margin: 0 8px;
            margin-top: 5px;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 100%;
                overflow: hidden;
            }
        }
        .symbol {
            font-size: 14px;
            font-weight: 500;
            line-height: 40px;
            color: var(--text-color);
        }
    }

    .ant-modal-footer {
        border: none;
        display: flex;
        justify-content: center;
        padding-bottom: 30px;
        .ant-btn-default {
            display: none;
        }
        .ant-btn-primary {
            height: 47px;
            width: 100%;
            max-width: 300px;
            font-size: var(--primary-btn-font-size);
            border-radius: var(--primary-btn-border-radius);
            line-height: 22px;
            color: var(--primary-btn-color);
            background: var(--primary-btn-background-color);
            margin-left: 0;
        }
    }
}

.still-confirm {
    background: var(--error-color) !important;
    color: white !important;
}

@primary-color: #5177f9;