.logout {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    .logout-error {
        padding: 30px;
        font-size: 16px;
        font-weight: normal;
        text-align: center;
        color: var(--error-color);
        width: 100%;
        word-wrap: break-word;
        margin-top: 30vh;
    }
    .logout-retry {
        padding: 0;
        margin-top: 20px;
        width: 225px;
        height: 47px;
        background: var(--primary-btn-background-color);
        font-size: var(--primary-btn-font-size);
        border-radius: var(--primary-btn-border-radius);
        font-weight: 600;
        color: var(--primary-btn-color);
        margin-left: 9px;
        text-align: center;
    }
    .logout-txt {
        color: var(--text-color);
        z-index: 1001;
        margin-top: calc(40vh + 80px);
    }
}

@primary-color: #5177f9;