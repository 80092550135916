.evm-gas {
    &.particle-pc-drawer {
        .ant-drawer-body {
            padding-top: 26px;
        }
    }
    .particle-title {
        font-size: 20px;
        font-weight: 700;
        height: 24px;
        line-height: 24px;
    }
    .ant-btn[disabled] {
        border: none;
    }
    color: var(--text-color);
    .ant-drawer-body {
        position: relative;
        padding: 17px 0px 10px 0px;
        padding-bottom: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .ant-drawer-header-close-only {
        display: none;
    }
    .gas-content-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-height: calc(70vh - 80px);
        overflow: auto;
        position: relative;
    }
    .gas-title {
        height: 24px;
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
    }
    .close-drawer {
        position: absolute;
        right: 22px;
        margin-top: -24px;
        margin-right: -6px;
        z-index: 100;
        font-size: 22px;
        color: var(--text-color);
    }
    .eth-amount {
        width: 326px;
        text-align: center;
        margin-top: 21px;
        font-size: 35px;
        font-weight: 700;
        line-height: 48px;
    }
    .eth-amount-about {
        text-align: center;
        margin-top: 6px;
        // height: 16px;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        color: var(--secondary-text-color);
    }
    .speed-time {
        text-align: center;
        margin-top: 18px;
        height: 15px;
        font-size: 13px;
        font-weight: bold;
        line-height: 15px;
        color: var(--green-color);
    }
    .speed-situation {
        width: 100%;
        margin-top: 18px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .situation-img {
            display: flex;
            justify-content: center;
            .radio-item {
                margin: 0 47px;
                transform: scale(1.3);
                .ant-radio-input {
                    & + .ant-radio-inner {
                        box-shadow: none;
                    }
                }
                .ant-radio-inner {
                    background: var(--background-color-1);
                }
                .ant-radio-checked .ant-radio-inner {
                    border-color: var(--accent-color) !important;
                }
                .ant-radio-inner::after {
                    background-color: var(--accent-color);
                }
            }
        }
        .situation-line {
            margin-top: 1px;
            margin-bottom: 4px;
            width: 221px;
            height: 4px;
            background: url(../../../common/images/radio_line.svg);
        }
        .situation-text {
            width: 330px;
            height: 15px;
            font-size: 13px;
            font-weight: bold;
            line-height: 15px;
            display: flex;
            justify-content: space-between;
            span {
                text-align: center;
                flex-shrink: 1;
                width: 100%;
            }
        }
    }
    .more-details {
        text-align: center;
        margin-top: 30px;
        margin-bottom: 20px;
        height: 15px;
        font-size: 13px;
        font-weight: bold;
        line-height: 15px;
        color: var(--accent-color);
        cursor: pointer;
        .arrow-icon {
            color: var(--accent-color);
            margin-left: 4px;
            position: relative;
            top: 1px;
            &[data-fold='false'] {
                transform: rotate(180deg);
            }
            svg {
                width: 12px;
                height: 12px;
            }
        }
    }

    .advanced-options {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        .options-form {
            width: 100%;
            padding: 0 30px;
            .form-son {
                // margin-left: 7.5px;
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
            }
            .img-box-minus {
                margin-top: 33px;
                width: 41px;
                height: 41px;
                position: absolute;
                z-index: 100;
                // left: calc(50 * var(--vw) - 159px);
                left: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                .minus-icon {
                    font-size: 19px;
                    color: var(--accent-color);
                }
            }
            .img-box-add {
                margin-top: 33px;
                width: 41px;
                height: 41px;
                position: absolute;
                z-index: 100;
                // right: calc(50 * var(--vw) - 159px);
                right: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                .add-icon {
                    font-size: 19px;
                    color: var(--accent-color);
                }
            }
            .oper-group {
                width: 100%;
                position: absolute;
                z-index: 100;
                display: flex;
                justify-content: space-between;
                // margin-top: 41px;
                img {
                    width: 19px;
                    margin: 0 calc((100 * var(--vw) - 296px) / 2);
                }
            }
            .mt1 {
                margin-top: 3px;
            }
            .estimate {
                font-size: 13px;
                font-weight: 500;
                line-height: 19px;
                color: var(--text-color);
                position: absolute;
                // right: calc((100 * var(--vw) - 326px) / 2);
                right: 24.5px;
                .estimate-val {
                    font-size: 11px;
                    font-weight: 400;
                }
            }
            .mt-a1 {
                width: 326px;
                margin-top: 53px;
                text-align: center;
            }
            .about-val {
                z-index: 100;
                font-size: 11px;
                font-weight: 400;
                line-height: 19px;
                color: var(--text-color);
                position: absolute;
                width: calc(100 * var(--vw) - 49px);
                text-align: center;
            }
            .mt-e0 {
                margin-top: 80px;
            }
            .error-tip {
                font-size: 11px;
                font-weight: 500;
                line-height: 19px;
                color: var(--error-color);
                position: absolute;
                // left: calc((100 * var(--vw) - 326px) / 2);
                left: 24.5px;
            }
            .ant-form-item {
                margin-bottom: 36px;
                .ant-form-item-label {
                    user-select: none;
                }
            }
            .ant-form-item-control-input-content {
                text-align: center;
            }
            .ant-input {
                // width: 326px;
                width: calc(100 * var(--vw) - 49px);
                height: 47px;
                background-color: var(--border-color-2);
                border: none;
                border-radius: var(--card-border-radius);
                text-align: center;
                font-size: 15px;
                font-weight: 500;
                // line-height: 19px;
                color: var(--text-color);
                &.ant-input:focus,
                .ant-input-focused {
                    border: none;
                    box-shadow: none;
                }
            }
            .special-input {
                padding: 0 40px 16px;
            }
            label {
                height: 15px;
                font-size: 13px;
                font-weight: bold;
                line-height: 15px;
                color: var(--text-color);
            }
        }
    }
    .item-save-btn {
        margin-bottom: 16px;
        width: 100%;
        padding: 0 24px;
        .save-btn {
            margin-top: 12px;
            width: 100%;
            height: 47px;
            background: var(--primary-btn-background-color);
            color: var(--primary-btn-color);
            border: none;
            border-radius: var(--primary-btn-border-radius);
            font-size: var(--primary-btn-font-size);
        }
    }
    .footer-box {
        position: relative;
    }
}

@primary-color: #5177f9;