.login-style {
    // padding-bottom: calc(100vh - 577px);
    // padding-bottom: calc(100vh - 623.55px);
    .form-item {
        margin-bottom: 0;
    }
}

.login-header0 {
    margin-top: 26px;
    width: 100%;
    height: 26px;
    font-size: 22px;
    font-weight: 700;
    line-height: 22px;
    color: var(--text-color);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    @media screen and (max-height: 620px) {
        margin-top: 18px;
    }
    img {
        margin-left: 16px;
        position: absolute;
        left: 0;
    }
}

.login-des {
    margin-top: 7px;
    height: 23px;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    color: var(--secondary-text-color);
    width: 100%;
    text-align: center;
}
.third-party-des {
    padding-bottom: calc(100vh - 238.55px);
}

.form-box {
    width: 100%;
    padding-top: 44px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (max-height: 620px) {
        padding-top: 20px;
    }

    .ant-form-item-explain-error {
        position: absolute;
    }
    .form-item {
        width: 100%;
        display: flex;
        text-align: center;
        flex-direction: column;
    }

    .phone-box {
        width: 316px;
        max-width: 90vw;
        height: 47px;
        line-height: 47px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--input-background-color);
        border: 1px solid var(--input-border-color);
        border-radius: var(--primary-btn-border-radius);
        font-size: 15px;
        @media (min-width: 600px) {
            max-width: calc(90 * var(--vw));
        }
        border: 1px solid var(--input-background-color);
        overflow: hidden;
        &[data-focus='true'] {
            border: 1px solid var(--accent-color);
        }
        .phone-select {
            cursor: pointer;
            height: 47px;
            width: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 15px;
            border-right: 1px solid var(--input-border-color);
            font-weight: 500;
            color: var(--text-color);
            flex-shrink: 0;
            padding-left: 8px;
            .down-more {
                margin-left: 8px;
                font-size: 10px;
            }
        }

        .country-code {
            height: 47px;
            line-height: 47px;
            color: var(--text-color);
            margin: 0 8px;
            flex-shrink: 0;
            display: flex;
            align-items: center;
        }

        .phone-input {
            width: 100%;
            font-size: 13px;
            height: 47px;
            line-height: 47px;
            border: none;
            background-color: transparent;
            color: var(--text-color);
            font-size: 15px;
            outline: none;
            box-shadow: none;
            flex-shrink: 1;
            padding: 0;
            border: none !important;
            border-radius: 0;
            &:focus {
                border: none !important;
                box-shadow: none !important;
            }
        }
    }
    .email-box {
        width: 316px;
        max-width: 90vw;
        height: 47px;
        line-height: 47px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--input-background-color);
        border: 1px solid var(--input-border-color);
        border-radius: var(--primary-btn-border-radius);
        font-size: 15px;
        @media (min-width: 600px) {
            max-width: calc(90 * var(--vw));
        }
        border: 1px solid var(--input-background-color);
        overflow: hidden;
        &[data-focus='true'] {
            border: 1px solid var(--accent-color);
        }
        .email-input {
            width: 100%;
            font-size: 13px;
            height: 47px;
            line-height: 47px;
            border: none;
            background-color: transparent;
            color: var(--text-color);
            font-size: 15px;
            outline: none;
            box-shadow: none;
            flex-shrink: 1;
            padding: 0;
            border: none !important;
            border-radius: 0;
            &:focus {
                border: none !important;
                box-shadow: none !important;
            }
        }
    }

    .phone-box,
    .ant-row {
        .phone-input,
        .email-input {
            height: 37px;
            line-height: 37px;
            width: 85%;
            overflow: hidden;
            border-color: var(--input-background-color) !important;

            .ant-form-item-control-input-content {
                position: relative;
                height: 37px;
            }

            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus {
                -webkit-text-fill-color: var(--text-color) !important;
                -webkit-box-shadow: 0 0 0 150px var(--input-background-color) inset !important;
            }
            &::-ms-reveal,
            &::-ms-clear {
                display: none;
            }
            &:focus {
                color: var(--text-color, #000) !important;
                border-color: var(--input-background-color) !important;
            }
        }
        .email-input {
            width: 90%;
        }
    }

    .error-tip {
        position: absolute;
        margin-top: 51px;
        // left: calc((100vw - 316px) / 2);
        left: 4px;
        font-size: 12px;
        font-weight: 400;
        line-height: 19px;
        color: var(--error-color);
        // top: 0;
        // right: 0;
        // margin-right: calc((100 * var(--vw) - 336px) / 2);
        // margin-top: -24px;
        // @media screen and (max-width: 374px) {
        //   margin-right: calc(5 * var(--vw));
        // }
        @media (min-width: 600px) {
            // left: calc((100 * var(--vw) - 316px) / 2);
            left: 4px;
        }
    }

    .submit-btn {
        margin-top: 35px;
        width: 316px;
        max-width: 90vw;
        @media (min-width: 600px) {
            max-width: calc(90 * var(--vw));
        }
    }
}

.login-or {
    margin: 35px 0;
    display: flex;
    height: 13px;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 15px;
    font-size: 11px;
    font-weight: 600;
    line-height: 22px;
    color: var(--secondary-text-color);
    white-space: nowrap;
    margin-bottom: 22px;
    &[data-size='mini'] {
        margin-top: 20px;
        margin-bottom: 10px;
    }
    @media screen and (max-height: 60px) {
        margin: 18px 0;
    }
    .or-line {
        width: 143px;
        border-top: 1px solid var(--icon-border-color);
    }
}

.change-login-mode {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 30px;
    // padding-bottom: calc((100vh - 545px));
    .change-social {
        width: 315px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        .first-way {
            // margin-left: 0;
            margin: 5px 10px;
        }
        img {
            cursor: pointer;
            // margin-left: 43px;
            margin: 5px 10px;
            width: 43px;
            height: 43px;
            border: 1px solid var(--icon-border-color);
            border-radius: 50%;
            background: #fff;
            &:hover {
                opacity: var(--hover-opacity);
            }
        }
        .collapse-login-icon {
            cursor: pointer;
            margin: 5px 10px;
            width: 43px;
            height: 43px;
            border-radius: 50%;
            color: grey;
            font-size: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            transform: rotate(180deg);
            &[data-is-collapse='false'] {
                transform: rotate(0deg);
            }
            svg {
                width: 43px;
                height: 43px;
                color: #b4b6c6;
                circle {
                    fill: var(--bg-color-2);
                }
            }
            &:hover {
                opacity: var(--hover-opacity);
            }
        }
    }
}

.select-country::-webkit-scrollbar {
    display: none;
}

.select-country {
    width: 310px;
    margin-left: 3px;
    max-width: 90vw;
    height: 291px;
    overflow: auto;
    overflow-x: hidden;
    // margin-top: 25px;
    border: 1px solid var(--input-background-color);
    background-color: var(--input-background-color);
    border-radius: var(--card-border-radius);
    // margin-left: calc((100vw - 316px) / 2);
    position: absolute;
    z-index: 1000;
    margin-top: 96px;
    // margin-top: 150px;
    // @media screen and (max-width: 374px) {
    //   margin-left: 5vw;
    //   overflow: auto;
    // }
    @media (min-width: 600px) {
        max-width: calc(90 * var(--vw));
    }

    &::-webkit-scrollbar {
        width: 0px;
        display: none;
    }

    .country-item {
        cursor: pointer;
        width: 100%;
        height: 46px;
        border-bottom: 1px solid var(--card-unclickable-background-color);
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:hover {
            background-color: var(--card-unclickable-background-color);
        }

        .country-box {
            display: flex;
            align-items: center;
        }
        .country-name {
            font-size: 14px;
            font-weight: 500;
            color: var(--text-color);
        }
        .country-code {
            font-size: 14px;
            font-weight: 500;
            color: var(--secondary-text-color);
            margin-right: 15px;
        }
        .country-flag {
            margin-left: 15px;
            margin-right: 10px;
        }
    }
}

// .footer {
//   width: 167px;
//   position: absolute;
//   bottom: 0;
//   margin-left: calc((100 * var(--vw) - 167px) / 2);
//   margin-bottom: 10px;
// }

@primary-color: #5177f9;