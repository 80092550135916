.turnstile-modal {
    .ant-modal-content {
        background: transparent !important;
        box-shadow: none !important;
    }
    .turnstile-box {
        display: flex;
        justify-content: center;
        align-items: center;
        width: auto !important;
        z-index: 2000;
        position: relative;
    }
    .turnstile-loading {
        display: flex;
        justify-content: center;
        align-items: center;
        width: calc(100% - 48px);
        height: 65px;
        position: absolute;
        .spin {
            font-size: 40px;
            color: var(--accent-color);
        }
    }
}

@primary-color: #5177f9;