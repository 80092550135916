.set-mp-success-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    .set-mp-success-icon {
        margin-top: 50px;
        height: 60px;
        width: 60px;
    }
    .set-mp-success {
        margin-top: 20px;
        font-size: 18px;
        line-height: 19px;
        color: var(--text-color);
        font-weight: bold;
    }
    .set-mp-success-tip {
        margin-top: 50px;
        line-height: 20px;
        span {
            color: var(--text-color);
            font-size: 14px;
            font-weight: 400;
        }
    }
    .back-btn {
        position: fixed;
        width: calc(100% - 36px);
        bottom: 40px;
        max-width: 800px;
        @media screen and (min-width: 600px) {
            width: calc(600px - 36px);
            left: calc(50% - 300px + 18px);
        }
    }
    .footer-box {
        position: fixed;
    }
}

@primary-color: #5177f9;