.login-container-box {
    width: 100%;
    display: flex;
    height: var(--doc-height);
    overflow: auto;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    position: relative;
    z-index: 1;
    .wrap {
        max-width: 316px;
        width: 100%;
        position: relative;
        top: 0;
        @media (min-width: 600px) {
            top: var(--login-container-box-wrap-top);
        }

        .login-logo {
            margin-top: 47px;
            height: 64px;
            display: flex;
            justify-content: center;
            align-items: center;
            line-height: 1.5715;
            .product-logo {
                max-width: 100%;
                height: 64px;
                object-fit: contain;
            }
        }
        .placeholder-wrap {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .footer-box {
        text-align: center;
        position: absolute;
        margin: 0;
        bottom: 10px;
        @media only screen and (max-height: 600px) {
            // small screen mobile devices
            position: unset;
            margin-top: 20px;
            margin-bottom: 10px;
            bottom: 0;
        }
        @media screen and (height: 300px) {
            //form mode
            position: absolute;
            top: unset;
            margin-bottom: 0;
            bottom: 10px;
        }
    }
}

[data-login-type="facebook"][data-hash="#/login"],[data-login-type="google"][data-hash="#/login"]{
    .footer-box{
        position: absolute !important;
    }
}

@primary-color: #5177f9;