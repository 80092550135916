.login-account-bind {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    // padding-top: 17px;
    overflow-y: auto;
    height: var(--doc-height);
    .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
        background-color: var(--input-background-color);
    }
    .ant-spin-spinning {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 20vh;
    }
    .account-title {
        font-size: 18px;
        font-weight: 600;
    }
    .account-bind-form {
        width: 100%;
        margin-top: 34px;
        padding: 0 18px;
        .account-input {
            width: 100%;
            height: 47px;
            color: var(--text-color);
            border: 1px solid var(--input-border-color);
            background-color: var(--input-background-color);
            border-radius: var(--primary-btn-border-radius);
        }
        .link-account-hint {
            align-self: flex-start;
            font-size: 12px;
            color: var(--secondary-text-color);
            padding-top: 6px;
        }
        .link-btn {
            margin-top: 15px;
            margin-bottom: 30px;
        }
        .unlink-btn {
            background: #ea4335;
            color: #fff;
            margin-top: 15px;
            margin-bottom: 30px;
        }
    }
    .footer-box {
        position: absolute;
        bottom: 10px;
        margin: 0;
        @media screen and (max-height: 350px) {
            position: unset;
            margin-bottom: 10px;
            bottom: 0;
        }
    }
}

@primary-color: #5177f9;