.welcome-container {
    width: 100%;
    height: var(--doc-height);
    .welcome {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 27px;
        color: var(--text-color);

        .wel-out {
            width: 70px;
            height: 70px;
        }

        .welcome-text {
            margin-top: 24px;
            font-size: 20px;
            line-height: 22px;
            color: var(--text-color);
        }

        .return-button {
            margin-top: 80px;
            min-width: 173px;
            max-width: 300px;
            height: 47px;
            opacity: 1;
            font-size: var(--primary-btn-font-size);
            border-radius: var(--primary-btn-border-radius);
            background: var(--primary-btn-background-color);
            color: var(--primary-btn-color);
            font-weight: 600;
            line-height: 47px;
            padding: 0 16px;
            &:hover {
                opacity: var(--hover-opacity);
            }
        }
    }
    .footer-box {
        position: absolute;
        bottom: 10px;
        margin: 0;
    }
}

@primary-color: #5177f9;