.mp-verify-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow: auto;
    height: var(--doc-height);
    padding: 0 18px;
    font-size: 14px;
    color: var(--text-color);
    background-color: var(--theme-background-color);
    .ant-form {
        max-width: 800px;
        width: 100%;
        min-height: 360px;
    }
    .ant-form-item {
        margin-bottom: 0;
    }
    .mp-verify-title {
        margin-top: 16px;
        line-height: 28px;
        font-size: 18px;
        color: var(--text-color);
        font-weight: 600;
    }
    .mp-input-name {
        align-self: flex-start;
        margin-top: 30px;
        font-weight: 400;
        color: var(--text-color);
    }
    .mp-input {
        margin-top: 6px;
    }
    .mp-input-error {
        color: var(--error-color);
        align-self: flex-start;
        margin-top: 8px;
    }
    .mp-tip-space {
        margin-top: 25px;
        line-height: 20px;
        margin-bottom: 110px;
        span {
            color: var(--text-color);
        }
    }
    .bottom-container {
        position: fixed;
        bottom: 0px;
        width: calc(100% - 36px);
        height: 110px;
        left: 18px;
        @media (min-width: 600px) {
            width: 600px;
            left: calc(50% - 300px);
            padding-left: 18px;
            padding-right: 18px;
        }
        .mp-next {
            width: 100%;
            position: relative;
            top: 24px;
            margin: auto;
            display: block;
            max-width: 800px;
        }
        .footer-box {
            position: absolute;
        }
    }
}

@primary-color: #5177f9;