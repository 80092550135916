.login-account-box {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    color: var(--text-color);
    // padding-top: 40px;
    overflow: auto;
    .login-account-title {
        font-size: 18px;
        font-weight: 600;
    }
    .login-account-description {
        margin: 32px 18px 40px 18px;
        font-size: 13px;
        font-weight: 400;
        color: var(--secondary-text-color);
    }
    .account-list {
        width: 100%;
        // border-top: 1px solid var(--card-divider-color);
        padding: 0 18px;
        .login-account-item {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: 60px;
            // border-bottom: 1px solid var(--card-divider-color);
            cursor: pointer;
            background: var(--card-unclickable-background-color);
            border-radius: var(--card-border-radius);
            margin-bottom: 10px;
            padding: 0 12px 0 14px;
            img {
                height: 35px;
                width: 35px;
                background-color: white;
                border-radius: 50%;
            }
            .login-account-name {
                flex-grow: 1;
                margin-left: 8px;
                font-weight: 600;
                font-size: 14px;
            }
            .login-account-value {
                flex-grow: 2;
                text-align: right;
                margin-right: 8px;
                color: var(--text-color);
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                max-width: 180px;
                &[data-no-linked='true'] {
                    color: var(--secondary-text-color);
                }
            }
            &:hover {
                opacity: var(--hover-opacity);
            }
        }
        .arrow-right-icon {
            color: var(--text-color);
            svg {
                width: 12px;
                height: 12px;
            }
        }
    }
}

@primary-color: #5177f9;