.particle-keywords-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.input-item {
    display: flex;
    width: 30px;
    height: 30px;
    margin: 0 10px;
    border-bottom: 2px solid var(--text-color);
    text-align: center;
    line-height: 30px;
    justify-content: center;
    padding-bottom: 30px;
    user-select: none;
    color: var(--text-color);
}

.particle-keywords-map {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    height: 245px;
    width: 100vw;
    position: fixed;
    left: 0;
    z-index: 555;
    bottom: 0;
    padding-bottom: 32px;
    background-color: var(--keyword-item-bg);
    border-top: var(--keyword-border-width) solid var(--keyword-border-color);
    @media (min-width: 600px) {
        width: calc(100 * var(--vw));
        left: calc(50% - 300px);
    }
    .footer-box {
        background: var(--keyword-all-bg);
        position: absolute;
        bottom: 0;
        margin: 0;
        height: 32px;
        display: flex;
        align-items: center;
    }
    &.loading {
        width: calc(100 * var(--vw));
        flex-direction: column;
        color: var(--secondary-text-color);
        font-size: 16px;
        border-top: none;
        justify-content: flex-start;
        background: transparent;
        @media (min-width: 600px) {
            width: calc(100 * var(--vw));
        }
        img {
            margin: 0 0 22px;
            width: 38px;
            height: 38px;
            margin-top: 40px;
        }

        .loading-icon {
            font-size: 20px;
            margin-top: 15px;
        }
    }

    [data-value='0'],
    [data-value='2'],
    [data-value='5'],
    [data-value='8'] {
        border-right: var(--keyword-border-width) solid var(--keyword-border-color);
        border-bottom: var(--keyword-border-width) solid var(--keyword-border-color);
        border-left: var(--keyword-border-width) solid var(--keyword-border-color);
    }

    [data-value='1'],
    [data-value='3'],
    [data-value='4'],
    [data-value='6'],
    [data-value='7'],
    [data-value='9'] {
        border-bottom: var(--keyword-border-width) solid var(--keyword-border-color);
    }

    & > .span {
        width: 33.33%;
        margin-left: 0.001%;
        height: 53px;
        display: inline-block;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--keyword-item-bg);
        font-size: 22px;
        line-height: 19px;
        color: var(--text-color);
        font-weight: 600;
        cursor: pointer;
        user-select: none;
        &[data-value=''] {
            background: var(--keyword-all-bg);
            &:active {
                opacity: 1;
            }
        }
        &:active {
            background: var(--keyword-item-active-bg);
        }
    }

    .del {
        background: var(--keyword-all-bg);

        .img {
            font-size: 20px;
        }
    }
}

@primary-color: #5177f9;