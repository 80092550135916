.phone-input-item-container {
    height: auto;
    overflow: initial;
    width: 100%;
    display: block;
    position: relative;
    .ant-input-affix-wrapper,
    .ant-input-number-group-wrapper {
        background: var(--input-background-color) !important;
        border-radius: var(--primary-btn-border-radius);
        border: 1px solid var(--input-border-color) !important;
        line-height: 47px;
        padding: 0;
        box-shadow: none !important;
        width: 100%;
        .ant-input-number-group-addon {
            border: none;
            border-end-start-radius: var(--primary-btn-border-radius);
            border-start-start-radius: var(--primary-btn-border-radius);
            background-color: var(--input-background-color);
            position: initial;
        }
        .ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number,
        .ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:hover {
            border: none;
            background: none;
        }
        .ant-input-number {
            border: none;
            background: none;
        }
        .ant-input-number-focused {
            box-shadow: none !important;
        }
        .ant-input-number-disabled {
            background: none;
        }

        .ant-input,
        .ant-input-number-input {
            color: var(--text-color);
            line-height: 47px;
            height: 47px;
            border-radius: 0;
            border: none !important;
            &:hover {
                border: none !important;
            }
        }
        &[data-focus='true'] {
            border: 1px solid var(--accent-color) !important;
        }
    }
    .ant-input[disabled] {
        width: 100%;
        height: 47px;
        color: var(--text-color);
        border: 1px solid var(--input-border-color);
        background-color: var(--input-background-color);
        border-radius: var(--primary-btn-border-radius);
    }
    .country-box {
        position: initial;
        cursor: pointer;
        .prefix-wrap {
            display: flex;
            color: var(--text-color);
            gap: 4px;
        }
        .account-select-country-list {
            width: 100%;
            height: 350px;
            overflow-y: auto;
            overflow-x: hidden;
            background-color: var(--input-background-color);
            z-index: 2;
            position: absolute;
            top: 54px;
            left: 0%;
            border-radius: var(--card-border-radius) !important;

            &::-webkit-scrollbar {
                width: 0px;
                display: none;
            }

            .search-input-wrap {
                .ant-input {
                    &:focus {
                        border: none !important;
                        box-shadow: none !important;
                    }
                }
                &.account-select-country-item {
                    padding-right: 13px;

                    &:hover {
                        background: var(--input-background-color);
                        &.search-input-wrap {
                            background: none;
                        }
                    }
                }
                .ant-input-clear-icon {
                    color: var(--text-color);
                }

                .ant-input-affix-wrapper {
                    border-color: var(--keyword-border-color) !important;
                }

                .ant-input-suffix {
                    position: relative;
                    right: 10px;
                }

                .search-input {
                    background-color: var(--input-background-color);
                    color: var(--text-color);
                    height: 32px;
                    width: 100%;
                    border-radius: var(--primary-btn-border-radius);
                    overflow: hidden;
                    .ant-input {
                        height: auto;
                        width: 100%;
                        padding-top: 4px;
                        padding-bottom: 4px;
                        line-height: initial;
                        height: auto;
                    }
                }
            }

            .no-data {
                color: var(--text-color);
                display: flex;
                align-items: center;
                justify-content: center;
                height: 160px;
            }
            .account-select-country-item {
                position: relative;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin: 0;
                padding: 5px 0 5px 13px;
                box-sizing: border-box;
                border-bottom: 1px solid var(--keyword-border-color);
                cursor: pointer;
                font-size: 14px;
                color: var(--text-color);
                height: 47px;

                &:hover {
                    background-color: var(--keyword-border-color);
                }

                div {
                    display: flex;
                    margin-right: 13px;
                    max-width: 80%;
                }
            }
        }

        input[type='number']::-webkit-inner-spin-button,
        input[type='number']::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        &.phone {
            display: flex;
            align-items: center;
        }

        .account-select-opt {
            width: 40px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            // border-right: 1px solid var(--input-border-color);
            cursor: pointer;
            flex-shrink: 0;
            padding-left: 4px;
            padding-right: 4px;

            .account-select-icon {
                display: flex;
                align-items: center;
                justify-content: center;
                overflow: hidden;
                font-size: 15px;
            }
            .down-more {
                margin-left: 6px;
                font-size: 10px;
            }
        }

        span {
            margin: 0;
            font-size: 15px;
            flex-shrink: 0;
        }
        .country-name {
            flex: 1;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        input {
            flex-shrink: 1;
            width: 100%;
            height: 100%;
            padding: 0 15px;
            box-sizing: border-box;
            border: none;
            outline: none;
            font-size: 15px;
            background-color: transparent;
            font-weight: 400;
            color: var(--text-color);
            line-height: 45px;
            height: 45px;
        }
    }
}

@primary-color: #5177f9;