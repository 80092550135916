.account-bind-container {
    @keyframes loading-inner {
        0% {
            transform: rotate(0deg);
        }

        50% {
            transform: rotate(180deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }
    .particle-loading,
    .result-content {
        height: 74vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        color: var(--text-color);
        .logo-img {
            width: 68px;
            height: 68px;
            font-size: 68px;
            margin-bottom: 10px;
        }
        .particle-loading-img {
            width: 30px;
            height: 30px;
            margin-top: 20px;
            animation: loading-inner 1.5s linear infinite;
        }
        h3 {
            color: var(--text-color);
        }
        p {
            font-size: 14px;
            color: var(--secondary-text-color);
        }
    }

    .link_btn {
        font-size: 14px;
        width: auto;
        height: 32px;
    }

    .result-content {
        .back {
            margin-top: 40px;
            button {
                display: flex;
                align-items: center;
            }
        }
    }
}

@primary-color: #5177f9;