:root {
  --app-background-color: #eaecf0;
  --hover-opacity: 0.85;
  --disable-opacity: 0.6;
  --error-color: #ea4335;
  --warning-color: #f7af5d;
  --green-color: #58c08f;
  --primary-btn-font-size: 17px;
  --primary-btn-border-radius: 18px;
  --modal-border-radius: 24px;
  --card-border-radius: 18px;
  --sign-bottom-menu-height: 94px;
  --set-payment-password-bar-height: 59px;
  --risk-bar-height: 0px;
  --loading-icon: url('../images/loading_fff.png');
  --icon-warning: url('../images/account/icon_waring_fff.png');
  --icon-danger: url('../images/no_warning.png');
  --master_password_icon: url('../images/master_password_icon_fff.svg');
  --close-icon: url('../images/close_fff_icon.svg');
  --keyword-all-bg: #ededed;
  --keyword-border-color: #f5f6fb;
  --keyword-item-bg: #fff;
  --keyword-item-active-bg: #ededed;
  --keyword-border-width: 1px;
  --border-color-1: #eaecf0;
  /* Light/中性色/#F9F9FA-弹窗文本框 输入框 */
  --border-color-2: #f9f9fa;
  /* Light/中性色/#FFFFFF-背景 */
  --background-color-1: #ffffff;
  --primary-btn-color: #fff;
  --primary-btn-background-color: #000;
  --secondary-btn-color: #000000;
  --secondary-btn-background-color: #eff0f2;
  --text-color: #000;
  --secondary-text-color: #8b8ea1;
  --background-color: #fff;
  --icon-border-color: #f5f6fb;
  --accent-color: #a257fa;
  --accent-shadow-color: rgba(162, 87, 250, 0.2);
  --input-background-color: #f9f9fa;
  --input-background-color-3: #f5f6fb;
  --input-border-color: #f5f3f5;
  --input-placeholder-color: #8b8ea1;
  --card-hover-border-color: #86899d;
  --card-border-color: #dcdfe6;
  --card-unclickable-background-color: #f9f9fa;
  --card-unclickable-border-color: #eef2f9;
  --card-divider-color: #eaecf0;
  --tag-background-color: rgba(139, 142, 159, 0.15);
  --modal-background-color: #fff;
  --tips-background-color: #eab98133;
  --low-emphasis-color: #dcdfe6;
  --bg-color-2: #f5f6fb;
  /* Light/中性色/#F5F6FB-移动端首页文本框 */
}
:root.dark,
:root body.dark {
  --app-background-color: #0c0e0f;
  --keyword-all-bg: #000;
  --keyword-item-bg: #212324;
  --keyword-border-color: #373841;
  --keyword-item-active-bg: #181c1e;
  --keyword-border-width: 1px;
  --border-color-1: #7a7a7a;
  --master_password_icon: url('../images/master_password_icon_000.svg');
  --loading-icon: url('../images/loading_000.png');
  --close-icon: url('../images/close_000_icon.svg');
  /* Dark/中性色/#303139-弹窗文本框、输入框 */
  --border-color-2: #303139;
  /* Dark/中性色/#232529-弹窗背景 */
  --background-color-1: #232529;
  --primary-btn-color: #000;
  --primary-btn-background-color: #fff;
  --secondary-btn-color: #fff;
  --secondary-btn-background-color: #41424a;
  --text-color: #fff;
  --secondary-text-color: #8b8ea1;
  --background-color: #000;
  --icon-border-color: #292b36;
  --accent-color: #a257fa;
  --input-background-color: #232529;
  --input-background-color-3: #414346;
  --input-border-color: #252525;
  --input-placeholder-color: #999999;
  --card-hover-border-color: #35383a;
  --card-border-color: #313334;
  --card-unclickable-background-color: #232529;
  --card-unclickable-border-color: #252525;
  --card-divider-color: #303139;
  --tag-background-color: rgba(139, 142, 159, 0.15);
  --modal-background-color: #232529;
  --tips-background-color: #eab98159;
  --low-emphasis-color: #42444b;
  --bg-color-2: #292b36;
  /* Dark/中性色/#292B36-移动端首页文本框 */
}
@font-face {
  font-family: 'SF Pro Text';
  font-display: auto;
  src: url('../font/SF-Pro-Text-Bold.ttf');
  src: url('../font/SF-Pro-Text-Bold.eot'), url('../font/SF-Pro-Text-Bold.eot?iefix') format('embedded-opentype'), url('../font/SF-Pro-Text-Bold.woff') format('woff'), url('../font/SF-Pro-Text-Bold.ttf') format('truetype'), url('../font/SF-Pro-Text-Bold.svg#SF Pro Text-Bold, SF Pro Text') format('svg');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'SF Pro Text';
  font-display: auto;
  src: url('../font/SF-Pro-Text-Medium.ttf');
  src: url('../font/SF-Pro-Text-Medium.eot'), url('../font/SF-Pro-Text-Medium.eot?iefix') format('embedded-opentype'), url('../font/SF-Pro-Text-Medium.woff') format('woff'), url('../font/SF-Pro-Text-Medium.ttf') format('truetype'), url('../font/SF-Pro-Text-Medium.svg#SF Pro Text-Medium, SF Pro Text') format('svg');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'SF Pro Text';
  font-display: auto;
  src: url('../font/SF-Pro-Text-Medium.ttf');
  src: url('../font/SF-Pro-Text-Medium.eot'), url('../font/SF-Pro-Text-Medium.eot?iefix') format('embedded-opentype'), url('../font/SF-Pro-Text-Medium.woff') format('woff'), url('../font/SF-Pro-Text-Medium.ttf') format('truetype'), url('../font/SF-Pro-Text-Medium.svg#SF Pro Text-Medium, SF Pro Text') format('svg');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'SF Pro Text';
  font-display: auto;
  src: url('../font/SF-Pro-Text-Regular.ttf');
  src: url('../font/SF-Pro-Text-Regular.eot'), url('../font/SF-Pro-Text-Regular.eot?iefix') format('embedded-opentype'), url('../font/SF-Pro-Text-Regular.woff') format('woff'), url('../font/SF-Pro-Text-Regular.ttf') format('truetype'), url('../font/SF-Pro-Text-Regular.svg#SF Pro Text-Regular, SF Pro Text') format('svg');
  font-weight: 400;
  font-style: normal;
}
:root {
  --font-family: 'SF Pro Text', 'SF Pro Icons', 'PingFang SC', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
}
body {
  overflow: hidden;
  color: var(--text-color);
  font-family: var(--font-family);
  font-smooth: antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body input:-webkit-autofill,
body input:-webkit-autofill:hover,
body input:-webkit-autofill:focus,
body textarea:-webkit-autofill,
body textarea:-webkit-autofill:hover,
body textarea:-webkit-autofill:focus,
body select:-webkit-autofill,
body select:-webkit-autofill:hover,
body select:-webkit-autofill:focus {
  -webkit-text-fill-color: var(--text-color);
  -webkit-box-shadow: 0 0 0px 100px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
  font-size: 15px;
  caret-color: var(--text-color);
  font-smooth: antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
button,
input,
optgroup,
select,
textarea {
  font-family: var(--font-family);
  font-smooth: antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
div#root {
  max-width: 600px;
  margin: auto;
  position: relative;
  min-height: 100%;
}
.icon-navigation-back {
  position: absolute;
  font-size: 28px;
  top: 16px;
  left: 16px;
  z-index: 99;
}
.close-iframe {
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 100;
}
.close-iframe.top-hint {
  top: 75px;
}
.ant-btn {
  border: none;
  background: var(--primary-btn-background-color);
  color: var(--primary-btn-color);
  border-radius: var(--primary-btn-border-radius);
  font-size: var(--primary-btn-font-size);
  padding: 3px 15px;
}
.ant-btn:hover {
  border: none;
  opacity: var(--hover-opacity);
}
.ant-btn:active .ant-btn:focus {
  border: none;
}
.ant-btn:disabled {
  pointer-events: none;
  opacity: var(--disable-opacity);
}
.ant-btn:disabled span {
  opacity: var(--disable-opacity);
}
.ant-modal-confirm-btns .ant-btn {
  background: var(--primary-btn-background-color);
  color: var(--primary-btn-color);
  border: none;
  padding-left: 18px;
  padding-right: 18px;
  border-radius: var(--primary-btn-border-radius);
}
.ant-modal-content {
  background-color: var(--modal-background-color) !important;
  border: none !important;
  border-radius: var(--modal-border-radius) !important;
}
.ant-modal-content .ant-modal-confirm-title {
  color: var(--text-color) !important;
}
.ant-modal-confirm-body .ant-modal-confirm-content {
  color: var(--text-color);
}
.ant-drawer-content-wrapper {
  width: 100vw !important;
  max-width: 600px !important;
  left: calc(50% - calc(var(--vw) * 50));
  box-shadow: none !important;
}
.ant-drawer-content {
  border-radius: var(--modal-border-radius) var(--modal-border-radius) 0 0;
  background-color: var(--background-color-1) !important;
}
.ant-form {
  color: var(--text-color);
}
.ant-form-item-explain-error {
  color: var(--error-color);
}
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  border-color: var(--error-color);
}
.ant-message-notice-content {
  font-weight: 500;
  box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding-left: 26px;
  padding-right: 26px;
}
body.dark {
  color: var(--text-color);
  position: relative;
}
body.dark .info-sign .btn-box {
  background-color: var(--card-unclickable-background-color);
}
body.dark .info-sign .risk-reminder {
  background-color: var(--card-unclickable-background-color);
}
body.dark .ant-message-notice-content {
  background: var(--modal-background-color);
  color: var(--text-color);
}
html:not([data-hash='#/login']) body,
html[data-login-type='phone'] body,
html[data-login-type='email'] body {
  background: var(--app-background-color) !important;
}
@media (min-width: 600px) {
  html.dark[data-hash='#/login']:not([data-login-type='phone']):not([data-login-type='email']) body::before {
    display: none !important;
  }
  html.dark[data-hash='#/login']:not([data-login-type='phone']):not([data-login-type='email']) body::after {
    display: none !important;
  }
  html.dark[data-hash='#/login']:not([data-login-type='phone']):not([data-login-type='email']) body #root {
    background: none !important;
  }
}
.hidden {
  display: none !important;
}
.pre-wrap {
  white-space: pre-wrap;
}
.hide-hover {
  --hover-opacity: 1 !important;
}
::selection {
  color: #ffffff;
  background-color: var(--accent-color);
}
.react-input-code {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.react-input-code .input-code-item {
  width: 44px;
  height: 44px;
  background-color: var(--input-background-color);
  padding: 0;
  font-size: 20px;
  text-align: center;
  margin-right: 6px;
  margin-left: 6px;
  text-transform: uppercase;
  color: var(--text-color);
  border: transparent;
  border-radius: 12px;
  background-clip: padding-box;
  caret-color: var(--text-color);
}
.react-input-code .input-code-item:focus {
  appearance: none;
  outline: 0;
}
.react-input-code .input-code-item:not(:placeholder-shown) {
  appearance: none;
  outline: 0;
}
.react-input-code input:-webkit-autofill,
.react-input-code input:-webkit-autofill:hover,
.react-input-code input:-webkit-autofill:focus,
.react-input-code input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 60px var(--input-background-color) inset !important;
  -webkit-text-fill-color: var(--text-color) !important;
}
.react-input-code .input-code-mask {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.primary-antd-btn {
  width: 100%;
  height: 47px;
  line-height: 22px;
  border-radius: var(--primary-btn-border-radius);
  color: var(--primary-btn-color);
  background: var(--primary-btn-background-color);
  font-size: var(--primary-btn-font-size);
  border: none;
  outline: none;
}
.primary-antd-btn:focus,
.primary-antd-btn:active {
  background: var(--primary-btn-background-color);
  color: var(--primary-btn-color);
}
.primary-antd-btn:hover {
  opacity: var(--hover-opacity);
  background: var(--primary-btn-background-color);
  color: var(--primary-btn-color);
}
.primary-antd-btn:disabled {
  opacity: var(--disable-opacity);
  background: var(--primary-btn-background-color);
  color: var(--primary-btn-color);
}
.primary-antd-btn:disabled span {
  opacity: var(--disable-opacity);
}
.primary-antd-btn.danger {
  background: var(--error-color) !important;
  color: white !important;
}
.primary-antd-btn.secondary {
  background: var(--secondary-btn-background-color);
  color: var(--secondary-btn-color);
}
.primary-antd-btn span {
  font-weight: 600;
}
.accent-antd-btn {
  width: 100%;
  height: 47px;
  line-height: 22px;
  border-radius: var(--primary-btn-border-radius);
  color: white;
  background-color: var(--accent-color);
  font-size: var(--primary-btn-font-size);
  border: none;
  outline: none;
}
.accent-antd-btn:focus,
.accent-antd-btn:active {
  background-color: var(--accent-color);
  color: white;
}
.accent-antd-btn:hover {
  opacity: var(--hover-opacity);
  background-color: var(--accent-color);
  color: white;
}
.accent-antd-btn:disabled {
  opacity: var(--disable-opacity);
  background-color: var(--accent-color);
  color: white;
}
.accent-antd-btn span {
  font-weight: 600;
}
.theme-font-icon {
  color: var(--text-color);
}
.theme-font-icon:hover {
  opacity: var(--hover-opacity);
}
.no-number {
  -moz-appearance: textfield !important;
}
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused,
.ant-input:focus,
.ant-radio-input:focus + .ant-radio-inner,
.ant-input-focused {
  box-shadow: none;
}
.ant-radio-inner {
  background: none;
  border-width: 2px !important;
  border-color: var(--border-color-1) !important;
}
.ant-radio-inner:after {
  width: 14px;
  height: 14px;
  margin-top: -7px;
  margin-left: -7px;
}
.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: var(--accent-color) !important;
}
.ant-message-notice-content {
  padding: 0;
  border-radius: var(--primary-btn-border-radius);
  overflow: hidden;
}
.ant-message-notice-content .ant-message-custom-content {
  padding: 10px 16px;
  color: #fff;
}
.ant-message-error {
  background: var(--error-color);
}
.ant-message-error .anticon {
  color: #fff;
}
.ant-message-success {
  background: var(--green-color);
}
.ant-message-success .anticon {
  color: #fff;
}
.color-scheme-dark {
  color-scheme: dark;
}
.color-scheme-dark iframe {
  color-scheme: none;
}
.m-warning-modal .ant-modal-confirm-btns {
  display: none;
}
.m-warning-modal .content {
  margin-top: 26px;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: var(--text-color);
  text-align: center;
}
.m-warning-modal .anticon-exclamation-circle {
  display: none;
}
.m-warning-modal .anticon-close {
  color: var(--secondary-text-color);
}
.m-warning-modal .footer-btns {
  display: flex;
  justify-content: flex-end;
  column-gap: 18px;
  margin-top: 30px;
}
.m-warning-modal .footer-btns .ant-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  flex: 1;
  height: 47px;
  text-align: center;
  font-size: 17px;
  font-weight: 600;
  line-height: 22px;
  color: var(--background-color);
}
.m-warning-modal .footer-btns .ant-btn:first-child {
  background-color: var(--secondary-btn-background-color);
  color: var(--secondary-btn-color);
}
.m-warning-modal .footer-btns .confirm-btn:hover,
.m-warning-modal .footer-btns .confirm-btn:focus,
.m-warning-modal .footer-btns .confirm-btn:active {
  background-color: var(--primary-btn-background-color);
  color: var(--primary-btn-color);
}
.m-warning-modal .footer-btns .ant-btn {
  box-shadow: none !important;
}
.m-warning-modal .footer-btns .ant-btn::after {
  display: none !important;
}
.ant-btn {
  font-weight: 600 !important;
}
.ant-input {
  padding-left: 14px;
  padding-right: 14px;
}
.ant-input:focus {
  border: 1px solid var(--accent-color) !important;
  border-radius: var(--primary-btn-border-radius);
}
.ant-input,
.input_email,
.ant-input-number-input,
.account-input-box input {
  border-radius: var(--primary-btn-border-radius);
  transition: none !important;
  border: 1px solid var(--input-background-color);
}
.ant-input:focus,
.input_email:focus,
.ant-input-number-input:focus,
.account-input-box input:focus {
  border: 1px solid var(--accent-color) !important;
  border-radius: var(--primary-btn-border-radius);
}
.ant-btn-primary {
  box-shadow: none !important;
}
.ant-tabs .ant-tabs-tab .ant-tabs-tab-btn {
  font-size: 16px;
}
.ant-tabs .ant-tabs-ink-bar {
  display: none;
}
.more-text-btn {
  color: var(--accent-color) !important;
}
input::placeholder {
  color: var(--input-placeholder-color) !important;
}
.ant-btn-loading {
  opacity: var(--disable-opacity);
}
.ant-btn-loading span {
  opacity: var(--disable-opacity);
}
.ant-btn-loading::before {
  display: none !important;
}
.ant-btn-loading .ant-btn-loading-icon {
  opacity: 1;
  background-image: var(--loading-icon);
  background-size: 19px 19px;
  height: 19px !important;
  width: 19px !important;
  position: relative;
  top: 3px;
  left: -3px;
  transition: initial !important;
  animation: none !important;
}
.ant-btn-loading .ant-btn-loading-icon .anticon {
  display: none;
}
.ant-modal {
  max-width: 340px;
  width: 90%;
}
.ant-modal .ant-modal-body {
  padding: 18px 18px;
}
.ant-modal .ant-modal-close-x {
  background: var(--close-icon);
  background-repeat: no-repeat;
  background-position: 12px 12px;
}
.ant-modal .ant-modal-close-x .anticon {
  display: none;
}
.ant-modal .ant-btn span {
  font-size: 14px;
}
input {
  caret-color: var(--accent-color);
}
.theme-light input:-webkit-autofill {
  -webkit-text-fill-color: var(--text-color, #000) !important;
}
