.create-passkeys-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--doc-height);
    .passkeys-icon {
        font-size: 100px;
    }
    .create-hint {
        font-size: 18px;
        color: var(--text-color);
    }
}

@primary-color: #5177f9;