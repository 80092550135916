.set-email-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--text-color);
    position: relative;
    overflow: auto;
    height: var(--doc-height);

    .footer-box {
        position: absolute;
        bottom: 10px;
        margin: 0;
        @media screen and (max-height: 500px) {
            display: none;
        }
    }

    .display-none {
        display: none;
    }
    .error-tip {
        text-align: left;
        margin-top: 6px;
        color: red;
        padding-left: 10px;
        margin-bottom: 0;
        transition: all 0.3s;
        height: 0px;
        width: 90%;
        opacity: 0;

        &.show {
            height: 30px;
            opacity: 1;
        }
    }

    .set-email-title {
        font-size: 22px;
        color: var(--text-color);
        margin-top: 60px;
    }

    .patment-tips1 {
        color: var(--text-color);
        font-size: 14px;
        width: 80vw;
        text-align: center;
        margin-top: 30px;
        @media (min-width: 600px) {
            width: calc(80 * var(--vw));
        }
    }

    .set-email-buttons {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        padding-bottom: 20px;
    }

    .success-icon {
        width: 50px;
        height: 50px;
        margin-top: 60px;
    }

    .set-email-desc-1 {
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        color: var(--text-color);
        margin: 15px 0 25px;
        padding: 0 18px;
        box-sizing: border-box;
        text-align: center;
    }

    .account-input-box {
        width: 90%;
        height: 47px;
        line-height: 47px;
        background-color: var(--input-background-color);
        opacity: 1;
        border-radius: var(--primary-btn-border-radius);
        border: 1px solid var(--input-border-color);
        margin-top: 28px;
        z-index: 3;
        position: relative;
        color: var(--text-color);

        .account-select-country {
            width: 96%;
            height: 210px;
            overflow-y: auto;
            background-color: var(--input-background-color);
            z-index: 2;
            position: absolute;
            top: 54px;
            left: 2%;
            border-radius: var(--card-border-radius) !important;

            &::-webkit-scrollbar {
                width: 0px;
                display: none;
            }

            .account-select-country-item {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin: 0;
                padding: 5px 0 5px 13px;
                box-sizing: border-box;
                border-bottom: 1px solid var(--card-unclickable-background-color);
                cursor: pointer;
                font-size: 14px;
                color: var(--text-color);
                height: 47px;
                width: 100%;

                &:hover {
                    background-color: var(--card-unclickable-background-color);
                }

                div {
                    display: flex;
                    margin-right: 13px;
                    .country-name {
                        max-width: 200px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }
        }

        input[type='number']::-webkit-inner-spin-button,
        input[type='number']::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        &.phone {
            display: flex;
            align-items: center;
            border-radius: var(--primary-btn-border-radius);
            border: 1px solid var(--input-background-color);
            // overflow: hidden;
            &[data-focus='true'] {
                border: 1px solid var(--accent-color) !important;
            }
            input {
                border: none !important;
                &:focus {
                    border: none !important;
                }
            }
        }

        .account-select-opt {
            width: 60px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            // border-right: 1px solid var(--input-border-color);
            cursor: pointer;
            flex-shrink: 0;
            padding-left: 8px;

            .account-select-icon {
                display: flex;
                align-items: center;
                justify-content: center;
                overflow: hidden;
                font-size: 15px;
            }
            .down-more {
                margin-left: 8px;
                font-size: 10px;
            }
        }

        span {
            margin: 0;
            font-size: 15px;
            flex-shrink: 0;
        }

        input {
            flex-shrink: 1;
            width: 100%;
            height: 100%;
            padding: 0 15px;
            box-sizing: border-box;
            outline: none;
            font-size: 15px;
            background-color: transparent;
            font-weight: 400;
            color: var(--text-color);
        }
    }
    .send-code-btn {
        margin-bottom: 0 !important;
    }
    .account-submit-btn {
        width: 90%;
        height: 47px;
        line-height: 47px;
        text-align: center;
        font-size: var(--primary-btn-font-size);
        opacity: 1;
        border-radius: var(--primary-btn-border-radius);
        margin-top: 38px;
        background: var(--primary-btn-background-color);
        color: var(--primary-btn-color);
        font-weight: 500;
        padding: 0;
        border: none;

        &.bottom-margin {
            margin-bottom: 100px;
        }

        &:hover {
            background: var(--primary-btn-background-color) !important;
            color: var(--primary-btn-color) !important;
            border: none;
            opacity: var(--hover-opacity);
        }
    }

    .account-switch-item {
        font-size: 13px;
        font-weight: 600;
        line-height: 19px;
        margin-top: 40px;
        color: var(--accent-color);
        cursor: pointer;
    }

    .set-email-desc-2 {
        min-width: 116px;
        padding: 0 15px;
        height: 23px;
        line-height: 23px;
        background: var(--tag-background-color);
        border-radius: var(--primary-btn-border-radius);
        opacity: 1;
        font-size: 12px;
        font-weight: 400;
        color: var(--secondary-text-color);
        text-align: center;
    }

    .code-error {
        font-size: 12px;
        font-weight: 400;
        color: var(--error-color);
        position: absolute;
        margin-top: 220px;
        left: calc((100vw - 316px) / 2);
        @media (min-width: 600px) {
            left: calc((var(--vw) * 100 - 316px) / 2);
        }
    }

    .back {
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        color: var(--accent-color);
        opacity: 1;
        margin-top: 90px;
        margin-bottom: 20px;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 8px;
        height: 19px;
        svg {
            color: var(--accent-color);
            position: relative;
            top: 0px;
        }
    }

    .send-again {
        background-color: transparent;
        border: none !important;
        margin-top: 20px;
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        outline: none !important;
        color: var(--text-color);
        box-shadow: none;

        &:hover {
            background-color: transparent !important;
            color: var(--text-color);
        }
        &:disabled {
            opacity: 0.5;
        }
    }
}

@primary-color: #5177f9;