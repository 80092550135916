.risk-modal {
    .risk-modal-title {
        font-size: 20px;
        font-weight: bold;
        color: var(--text-color);
        text-align: center;
    }
    .risk-modal-content {
        width: 100%;
        margin: 22px 0;
        max-height: 240px;
    }
    .risk-bottom-btn-box {
        display: flex;
        gap: 16px;
    }
}

@primary-color: #5177f9;