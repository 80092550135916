.payment-password-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--text-color);
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    & > .wrapper {
        padding: 0 30px;
    }
    .display-none {
        display: none;
    }
    .page-title{
        margin-top: 0px;
        font-size: 22px;
        color: var(--text-color);
        text-align: center;    
    }
}

.payment-title {
    font-size: 22px;
    color: var(--text-color);
    margin-top: 60px;
}

.patment-tips1 {
    color: var(--text-color);
    font-size: 14px;
    width: 80vw;
    text-align: center;
    margin-top: 30px;
    @media (min-width: 600px) {
        width: calc(80 * var(--vw));
    }
}

.payment-buttons {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding-bottom: 20px;
}

.success-icon {
    width: 50px;
    height: 50px;
    margin-top: 60px;
}

.payment-desc-1 {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    color: var(--text-color);
    margin: 15px 0 75px;
    text-align: center;
    box-sizing: border-box;
}

@primary-color: #5177f9;