.device-details-wrapper {
    -webkit-overflow-scrolling: touch;
}
.device-details-container {
    width: 100%;
    color: var(--text-color);
    overflow: auto;
    height: var(--doc-height);
    padding-bottom: 90px;
    .details-list {
        margin-top: 20px;
        padding-bottom: 50px;
        padding: 0 18px;
        .item {
            height: 58px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: var(--card-unclickable-background-color);
            border-radius: var(--card-border-radius);
            margin-bottom: 10px;
            padding: 0 12px 0 14px;
            .right {
                font-size: 12px;
                font-weight: 400;
            }
        }
    }
    .btns {
        padding: 0 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        width: 100%;
        bottom: 37px;
        background: var(--theme-background-color);
        .confirm-delete-btn {
            width: 100%;
            height: 46px;
            line-height: 46px;
            max-width: 360px;
            padding: 0;
        }
    }

    .footer-box {
        height: 37px;
        bottom: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: var(--theme-background-color);
    }
}
.delete-confirm-modal {
    .ant-modal-confirm-btns,
    .anticon-exclamation-circle {
        display: none;
    }
    @media (max-width: 767px) {
        .ant-modal {
            max-width: calc(100vw - 36px);
            margin: 8px auto;
        }
    }
    .ant-modal-confirm-content {
        margin: 0 !important;
    }

    .ant-modal-body {
        position: relative;
        padding: 26px 18px;
    }
    .content-text {
        font-size: 20px;
        font-weight: 500;
        text-align: center;
        margin-bottom: 34px;
        margin-top: 40px;
        color: var(--text-color);
    }
    .close-btn {
        position: absolute;
        font-size: 30px;
        top: 10px;
        right: 16px;
    }
    .btns {
        display: flex;
        gap: 20px;
        justify-content: space-between;
        button {
            flex: 1;
            height: 46px;
            &.cancel-btn {
                background: var(--secondary-btn-background-color);
                color: var(--text-color);
            }
        }
    }
}

@primary-color: #5177f9;