.set-mp-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: var(--text-color);
    font-size: 14px;
    position: relative;
    overflow: auto;
    padding: 0 18px;
    height: var(--doc-height);
    align-items: center;
    .ant-form {
        width: 100%;
        max-width: 800px;
    }
    .ant-form-item {
        margin-bottom: 0px;
    }
    .set-mp-title {
        margin-top: 16px;
        line-height: 28px;
        font-size: 18px;
        color: var(--text-color);
        font-weight: 600;
        align-self: center;
    }

    .mp-input-name {
        margin-top: 37px;
        color: var(--text-color);
    }
    .mp-input {
        margin-top: 6px;
    }
    .mp-input-error {
        color: var(--error-color);
        margin-top: 8px;
        margin-bottom: 8px;
    }
    .mp-confirm-input-name {
        margin-top: 21px;
    }
    .description-space {
        line-height: 20px;
        margin-bottom: 100px;
        margin-top: 25px;
        span {
            color: var(--text-color);
        }
    }
    .set-mp-footer {
        position: fixed;
        bottom: 0px;
        width: 100%;
        z-index: 100;
        height: 110px;
        left: 0;
        padding: 0 18px;
        background-color: var(--theme-background-color);

        @media screen and (min-width: 600px) {
            width: 600px;
            left: calc(50% - 300px);
            padding-left: 18px;
            padding-right: 18px;
        }

        .set-mp-btn {
            position: relative;
            top: 24px;
            margin: auto;
            display: block;
            max-width: 800px;
            width: 100%;
        }
        .footer-box {
            position: absolute;
            left: 0;
        }
    }

    .mp-loading-container {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
        .mp-icon-loading {
            width: 200px;
            height: 200px;
            margin-top: 50px;
        }
        .loading-tip {
            margin-top: 20px;
            align-items: center;
            span {
                color: var(--text-color);
            }
        }
        .footer-box {
            position: fixed;
        }
    }
}

@primary-color: #5177f9;