.set-password-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--text-color);
    position: relative;
    overflow: hidden;

    .display-none {
        display: none;
    }

    .password-mistake {
        color: var(--error-color);
        width: 100%;
        height: auto;
        padding-bottom: 20px;
        text-align: center;
        margin-top: 50px;
    }

    .payment-title {
        font-size: 22px;
        color: var(--text-color);
        margin-top: 60px;
    }

    .patment-tips1 {
        color: var(--text-color);
        font-size: 14px;
        width: 80vw;
        text-align: center;
        margin-top: 30px;
        @media (min-width: 600px) {
            width: calc(80 * var(--vw));
        }
    }

    .payment-buttons2 {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 50px;
        @media screen and (max-height: 565px) {
            margin-top: 0px;
            height: calc(var(--doc-height) - 245px - 106px - 31px - 42px);
        }
        .payment-main-button2 {
            width: 90%;
            height: 47px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: var(--primary-btn-color);
            background: var(--primary-btn-background-color);
            opacity: 1;
            font-size: var(--primary-btn-font-size);
            border-radius: var(--primary-btn-border-radius);
            font-weight: 500;
            border: none !important;
            outline: none;

            &:hover {
                color: var(--primary-btn-color);
                background: var(--primary-btn-background-color);
            }
            &:disabled {
                opacity: 0.5;
            }
        }
    }

    .success-icon {
        width: 50px;
        height: 50px;
        margin-top: 60px;
    }

    .payment-desc-1 {
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        color: var(--text-color);
        margin: 15px 0 0;
        box-sizing: border-box;
        text-align: center;
    }
    .keyboard-container {
        padding-left: 30px;
        padding-right: 30px;
        margin-top: 50px;
        width: 100%;
        @media screen and (max-height: 565px) {
            margin-top: 10px;
        }
    }
}

@primary-color: #5177f9;