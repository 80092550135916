.master-password-drawer {
    .particle-title {
        font-size: 20px;
        font-weight: bold;
        color: var(--text-color);
    }
}
.particle-pc-drawer {
    .mp-modal-container {
        padding-top: 30px;
    }
}
.mp-modal-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    .mp-set-desc {
        font-size: 16px;
        font-weight: 400;
        margin-top: 30px;
        color: var(--secondary-text-color);
        text-align: center;
    }
    .mp-set-img {
        width: 250px;
        height: 156px;
        font-size: 250px;
        background-image: var(--master_password_icon);
        margin-bottom: 44px;
        margin-top: 38px;
    }
    .mp-antd-bt {
        width: 100%;
        height: 47px;
        line-height: 47px;
        margin-bottom: 15px;
        font-size: var(--primary-btn-font-size);
        border-radius: var(--primary-btn-border-radius);
        color: var(--primary-btn-color);
        background: var(--primary-btn-background-color);
        border: none;
        outline: none;
        line-height: 22px;

        &:hover {
            opacity: var(--hover-opacity);
        }
    }
    .mp-set-now {
        margin-top: 15px;
        max-width: 800px;
    }
    .mp-set-later {
        background: var(--secondary-btn-background-color);
        color: var(--secondary-btn-color);
        max-width: 800px;
    }
}

.footer-box {
    position: absolute;
    bottom: 10px;
    margin: 0;
}

@primary-color: #5177f9;