.set-viery-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--text-color);
    position: relative;
    overflow: auto;
    height: var(--doc-height);

    .footer-box {
        position: absolute;
        bottom: 10px;
        margin: 0;
        @media screen and (max-height: 520px) {
            position: unset;
            margin-bottom: 10px;
            bottom: 0;
            margin-top: 20px;
        }
    }

    .display-none {
        display: none;
    }
    .error-tip {
        text-align: left;
        margin-top: 6px;
        color: red;
        padding-left: 10px;
        margin-bottom: 0;
        transition: all 0.3s;
        height: 0px;
        width: 90%;
        opacity: 0;

        &.show {
            height: 30px;
            opacity: 1;
        }
    }

    .set-email-title {
        font-size: 22px;
        color: var(--text-color);
        margin-top: 60px;
    }

    .patment-tips1 {
        color: var(--text-color);
        font-size: 14px;
        width: 80vw;
        text-align: center;
        margin-top: 30px;
        @media (min-width: 600px) {
            width: calc(80 * var(--vw));
        }
    }

    .set-email-buttons {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        padding-bottom: 20px;
    }

    .success-icon {
        width: 50px;
        height: 50px;
        margin-top: 60px;
    }

    .set-email-desc-1 {
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        color: var(--text-color);
        margin: 15px 0 25px;
        padding: 0 18px;
        box-sizing: border-box;
        text-align: center;
    }

    .account-submit-btn {
        width: 90%;
        height: 47px;
        line-height: 47px;
        text-align: center;
        font-size: var(--primary-btn-font-size);
        border-radius: var(--primary-btn-border-radius);
        opacity: 1;
        margin-top: 38px;
        background: var(--primary-btn-background-color);
        color: var(--primary-btn-color);
        font-weight: 600;
        padding: 0;
        border: none;

        &:hover {
            background: var(--primary-btn-background-color) !important;
            color: var(--primary-btn-color) !important;
            border: none;
            opacity: var(--hover-opacity);
        }
    }

    .account-switch-item {
        font-size: 13px;
        font-weight: 600;
        line-height: 19px;
        margin-top: 40px;
        color: var(--accent-color);
        cursor: pointer;
    }

    .set-email-desc-2 {
        min-width: 116px;
        padding: 0 15px;
        height: 23px;
        line-height: 23px;
        background: var(--tag-background-color);
        opacity: 1;
        border-radius: var(--primary-btn-border-radius);
        font-size: 12px;
        font-weight: 400;
        color: var(--secondary-text-color);
        text-align: center;
        margin: 20px 0 28px;
    }

    .back {
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        color: var(--accent-color);
        opacity: 1;
        margin-top: 90px;
        cursor: pointer;
        display: flex;
        align-items: center;
        display: flex;
        align-items: center;
        gap: 8px;
        svg{
            color: var(--accent-color);
            position: relative;
            top:-1px;
        }
    }

    .code-error {
        font-size: 12px;
        font-weight: 400;
        color: var(--error-color);
        position: absolute;
        margin-top: 224px;
        left: calc((100vw - 316px) / 2);
        @media (min-width: 600px) {
            left: calc((var(--vw) * 100 - 316px) / 2);
        }
    }

    .send-again {
        border: none !important;
        margin-top: 20px;
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        outline: none !important;
        color: var(--text-color);
        box-shadow: none;
        background-color: transparent;

        &:hover {
            background-color: transparent !important;
            color: var(--text-color) !important;
        }
        &:disabled {
            opacity: 0.5;
        }
    }
}

@primary-color: #5177f9;